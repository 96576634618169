import React from 'react'
import LeftCreditTable from "./LeftCreditTable";
import DatePicker from "react-datepicker";

const LeftCredit = ({ handleRangeType, creditLeft, showDatepicker, startDate, endDate, setStartDate, setEndDate }) => {
    const creditTableHeader = ["Total Credit", "Amount Paid", "Amount Left", "Guest", "Address", "Email", "ID", "Phone"];

    return (
        <div className='left-credit'>
            <div>
                <div className="pl-[3rem]">
                    <select onChange={handleRangeType}>
                        <option value={""} disabled selected>Please select an option</option>
                        <option value={"All"}>All</option>
                        <option value={"Ranged"}>Range</option>
                    </select>
                </div>
                <div>
                    {showDatepicker &&
                        <div className='flex pl-[3rem] py-2'>
                            <div>
                                <label>Start:</label>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                            </div>
                            <div>
                                <label>End:</label>
                                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                            </div>
                        </div>}
                </div>
            </div>

            <LeftCreditTable header={creditTableHeader} creditLeft={creditLeft} />
        </div>
    )
}

export default LeftCredit