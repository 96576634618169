import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SelectSearch from "react-select-search";
import axios from "axios";

const SelectSearchInput = ({
  token,
  setToken,
  selectedOutlet,
  setSelectedOutlet,
  setCompany, // Added this prop
}) => {
  let navigate = useNavigate();
  let url = process.env.REACT_APP_BASE_URL;
  const [listOutlet, setListOutlet] = useState([]);

  useEffect(() => {
    let tokenCheck = localStorage.getItem("token");
    if (!tokenCheck) {
      navigate("/"); // Redirect to login if no token
    } else {
      setToken(localStorage.getItem("token"));
    }
  }, []);

  useEffect(() => {
    if (token) {
      axios
        .post(`${url}/outlets`, {
          token: token,
        })
        .then((response) => {
          setListOutlet(response.data);
          // Set the first outlet as default if it's not already selected
          if (!selectedOutlet && response.data.length > 0) {
            setSelectedOutlet(response.data[0]?.value); // Set the first outlet
            setCompany(response.data[0]?.company); // Set company from the first outlet
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token, selectedOutlet, setCompany, setSelectedOutlet]);

  return (
    <div className="select-search">
      <SelectSearch
        defaultValue={selectedOutlet}
        search
        placeholder={"Select Outlet Name"}
        onChange={(event) => {
          const selectedOutlet = event;
          const selectedCompany = listOutlet.find(
            (outlet) => outlet.value === selectedOutlet
          )?.company;
          setSelectedOutlet(selectedOutlet);
          setCompany(selectedCompany); // Pass the company to parent component
        }}
        options={listOutlet}
      />
    </div>
  );
};

export default SelectSearchInput;
