import React from 'react'
import { IoFastFoodOutline, IoFastFood, IoPeopleOutline } from "react-icons/io5";
import { FcClock } from "react-icons/fc";
import { RiNumbersLine } from "react-icons/ri";
import "../../scss/info.scss";
import { useNavigate } from 'react-router-dom';

const Info = ({ info, selectedOutlet }) => {
    const { completedToday, cooking, itemsCompetedToday, started, totalOrdersToday, voidTotalToday } = info
    const navigate = useNavigate()

    const handleVoidNavigate = () => {
        if (selectedOutlet) {
            navigate('/voidtoday', { state: { outlet: selectedOutlet } });
        }
    };

    return (
        <div className='dashboard'>
            <div className="row">
                <div className="col dashboard-items">
                    <FcClock className='icon' />
                    <div className='padding-dashboard-items'>
                        <label>Orders not seen:<span>{started}</span></label>
                    </div>
                </div>
                <div className="col dashboard-items">
                    <IoFastFoodOutline className='icon' />
                    <div className='padding-dashboard-items'>
                        <label>Active Orders:<span>{cooking}</span></label>

                    </div>
                </div>
                <div className="col dashboard-items">
                    <IoFastFood className='icon' />
                    <div className='padding-dashboard-items'>
                        <label>Completed Orders:<span>{completedToday}</span></label>

                    </div>
                </div>
                <div className="col dashboard-items">
                    <IoPeopleOutline className='icon' />
                    <div className='padding-dashboard-items'>
                        <label>Total Orders today:<span>{totalOrdersToday}</span></label>

                    </div>
                </div>
                <div className="col dashboard-items">
                    <RiNumbersLine className='icon' />
                    <div className='padding-dashboard-items'>
                        <label>Total items prepared:<span>{(itemsCompetedToday === null) ? "0" : itemsCompetedToday}</span></label>
                    </div>
                </div>
                <button className="col dashboard-items-void" onClick={handleVoidNavigate}>
                    <RiNumbersLine className='icon' />
                    <span className='padding-dashboard-items'>
                        <label>Total void today:<span>{(voidTotalToday === null) ? "0" : voidTotalToday}</span></label>
                    </span>
                </button>
            </div>
        </div>
    )
}

export default Info