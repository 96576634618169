import React, { useState, useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "../../../scss/sticky.scss";

const BeverageTable = ({ beverage, FoodBeverageSum, outlet, token, startDate, endDate }) => {
  const tableRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemDetails, setItemDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch item details
  const fetchItemDetails = async (itemName) => {
    setLoading(true);
    setError(null);

    const formattedStartDate = new Date(startDate).toISOString().split("T")[0];
    const formattedEndDate = new Date(endDate).toISOString().split("T")[0];

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/itemssoldcountbillwise/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          outlet,
          dateStart: formattedStartDate,
          dateEnd: formattedEndDate,
          itemName,
          token,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setItemDetails(data);
      setIsModalOpen(true);
    } catch (error) {
      setError("Error fetching item details");
    } finally {
      setLoading(false);
    }
  };

  const openModal = (item) => {
    setSelectedItem(item);
    fetchItemDetails(item.itemName);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    setItemDetails([]);
  };

  return (
    <div className="flex flex-col">
      <div className="table-responsive-beverage">
        <div className="table-responsive-food-header">
          <div className="bg-heading-food">
            <h4 className="beverage-heading">Beverage</h4>
          </div>
          <DownloadTableExcel filename="beverages_table" sheet="beverages" currentTableRef={tableRef.current}>
            <button className="export"> Export </button>
          </DownloadTableExcel>
        </div>
        <div className="table-responsive" ref={tableRef}>
          <table className="table-beverage">
            <thead>
              <tr className="sticky-header">
                <th>Group</th>
                <th>Item Name</th>
                <th>Item Rate (Rs)</th>
                <th>Quantity</th>
                <th>Total (Rs)</th>
              </tr>
            </thead>
            <tbody>
              {!beverage?.error &&
                beverage.map((item, index) => (
                  <tr key={index} onClick={() => openModal(item)}>
                    <td>{item.Description}</td>
                    <td className="cursor-pointer">{item.itemName}</td>
                    <td>{item.itemrate}</td>
                    <td>{item.quantity}</td>
                    <td>{item.total}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {FoodBeverageSum.map((item, index) => (
          <div className="flex justify-end" key={index}>
            <p className="font-bold text-xs p-2">Quantity: {item.beveragequantity}</p>
            <p className="font-bold text-xs p-1 pt-0 mr-2">Total: {item.beveragetotal}</p>
          </div>
        ))}
      </div>

      {/* Modal */}
      {isModalOpen && selectedItem && (
        <div className="modal-overlay">
          <div className="modal-content">
            <span className="close-btn" onClick={closeModal}>×</span>

            {/* Header Section with h2 and span */}
            <div className="modal-header">
              <h2 className="modal-title">{selectedItem.itemName}</h2>
              <span className="modal-group"><strong>Group:</strong> {selectedItem.Description}</span>
              <span className="modal-date-range">Sales Report from {new Date(startDate).toLocaleDateString()} to {new Date(endDate).toLocaleDateString()}</span>
            </div>

            {/* Table for Item Details */}
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              <div className="modal-table-container">
                <table className="modal-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Bill No</th>
                      <th>Type</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Employee</th>
                      <th>No. of Guests</th>
                      <th>Guest Name</th>
                      <th>Item Count</th>
                      <th>Rate</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemDetails.length > 0 ? (
                      itemDetails.map((row, index) => (
                        <tr key={index}>
                          <td>{row.Date}</td>
                          <td>{row.Bill_No}</td>
                          <td>{row.Type}</td>
                          <td>{row.Start_Time}</td>
                          <td>{row.End_Time}</td>
                          <td>{row.Employee}</td>
                          <td>{row.NoOfGuests}</td>
                          <td>{row.GuestName || "N/A"}</td>
                          <td>{row.Count}</td>
                          <td>{row.ItemRate}</td>
                          <td>{row.Total}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10">No data available</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Total</td>
                      <td>{itemDetails.length}</td>
                      <td colSpan={6}></td>
                      <td>
                        {itemDetails.reduce((total, row) => total + row.Count, 0)}
                      </td>
                      <td></td>
                      <td>{itemDetails.reduce((total, row) => total + row.Total, 0).toFixed(2)}</td>
                    </tr>
                  </tfoot>

                </table>
              </div>
            )}
          </div>
        </div>
      )}

    </div>
  );
};

export default BeverageTable;