import React from "react";
import { formatSalesValue } from "../../utils/formatUtils";

const SalesForecastTable = ({ forecastData }) => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

    return (
        <>
            <h2 className="2xl:text-xl xl:text-lg font-semibold mb-4 text-center">Sales Forecast Table</h2>
            <div className="overflow-x-auto mb-6">
                <table className="min-w-full border-collapse border border-gray-300 2xl:text-base xl:text-sm">
                    <thead>
                        <tr className="bg-gray-100 text-center">
                            <th className="border border-gray-300 px-4 py-2">Date</th>
                            <th className="border border-gray-300 px-4 py-2">Actual Sales</th>
                            <th className="border border-gray-300 px-4 py-2">Forecast</th>
                            <th className="border border-gray-300 px-4 py-2">Lower Estimate</th>
                            <th className="border border-gray-300 px-4 py-2">Upper Estimate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {forecastData.length > 0 ? (
                            forecastData
                                .sort((a, b) => new Date(b.ds) - new Date(a.ds)) // Sort by date in descending order
                                .map((item, index) => {
                                    const isToday = item.ds === today; // Check if the item date is today
                                    return (
                                        <tr
                                            key={index}
                                            className={`text-center ${isToday ? "bg-[#F67F86] text-white hover:bg-[#F67F86]" : "hover:bg-gray-50"}`} // Apply the highlight color for today's row
                                        >
                                            <td className="border border-gray-300 px-4 py-2">{item.ds}</td>
                                            <td className="border border-gray-300 px-4 py-2">
                                                {formatSalesValue(item.actual_sales)}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2">
                                                {formatSalesValue(item.yhat)}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2">
                                                {formatSalesValue(item.yhat_lower)}
                                            </td>
                                            <td className="border border-gray-300 px-4 py-2">
                                                {formatSalesValue(item.yhat_upper)}
                                            </td>
                                        </tr>
                                    );
                                })
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center py-2">
                                    No Data Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default SalesForecastTable;
