import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import SelectSearchInput from "../SelectSearch";
import Navbar from "../Navbar";
import Footer from "../Footer";

function MenuMaster() {
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState("");
  const [selectedOutlet, setSelectedOutlet] = useState(""); // Default empty outlet
  const [company, setCompany] = useState(""); // To store the company name
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const Url = process.env.REACT_APP_BASE_URL;

  // Token check and redirection on page load
  useEffect(() => {
    const tokenCheck = localStorage.getItem("token");
    if (!tokenCheck) {
      navigate("/"); // Redirect to login page if no token is found
    } else {
      setToken(tokenCheck);
    }
  }, [navigate]);

  // Fetch outlets and set the first one as the default on component load
  useEffect(() => {
    const fetchOutlets = async () => {
      try {
        const response = await axios.post(`${Url}/outlets`, { token: token });
        // Set outlets data and default outlet
        if (response.data.length > 0) {
          setSelectedOutlet(response.data[0]?.value);
          setCompany(response.data[0]?.company); // Set company from the first outlet
        }
      } catch (error) {
        console.error("Error fetching outlets:", error);
      }
    };

    if (token && !selectedOutlet) {
      fetchOutlets(); // Fetch outlets only when token is available and outlet is not set
    }
  }, [token, selectedOutlet, Url]);

  // Fetch menu data whenever the token or selectedOutlet changes
  useEffect(() => {
    if (!token || !selectedOutlet) return; // If there's no token or outlet, don't make the request

    setLoading(true);
    axios
      .post(`${Url}/item-lists`, {
        token: token,
        outlet: selectedOutlet,
      })
      .then((response) => {
        setMenuData(response.data); // Assuming the API returns the correct structure
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to fetch menu data");
        setLoading(false);
      });

    // Update the search parameters for the outlet if it's different from the current one
    if (searchParams.get("outletName") !== selectedOutlet) {
      setSearchParams({ outletName: selectedOutlet });
    }
  }, [selectedOutlet, token, setSearchParams, Url, searchParams]);

  // A reusable table component to render menu items
  const renderMenuItems = (items) => (
    <table className="min-w-full table-auto bg-white rounded-lg shadow-lg">
      <thead className="bg-gray-200">
        <tr>
          <th className="px-3 py-2 text-left text-lg text-gray-600">Name</th>
          <th className="px-3 py-2 text-left text-lg text-gray-600">Price</th>
          <th className="px-3 py-2 text-left text-lg text-gray-600">State</th>
          <th className="px-3 py-2 text-left text-lg text-gray-600">
            Discount Exempt
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, itemIndex) => (
          <tr key={itemIndex} className="border-b">
            {/* Wrap item name with a clickable button to navigate */}
            <td className="px-3 py-2 text-gray-700 text-left">
              <button
                className="text-blue-600 text-left"
                onClick={() => {
                  localStorage.setItem("outlet", selectedOutlet);
                  localStorage.setItem("group", item.Description);
                  localStorage.setItem("item", item.Name);
                  localStorage.setItem("type", item.Type);
                  navigate("/itemdata");
                }}>
                {item.Name}
              </button>
            </td>
            <td className="px-3 py-2 text-gray-700">Rs.{item.Price}</td>
            <td className="px-3 py-2 text-gray-700">{item.state}</td>
            <td className="px-3 py-2 text-gray-700">{item.discountExempt}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  // Filter and categorize items for Food, Beverage, and Others
  const foodItems = menuData.filter((item) => item.Type === "Food");
  const beverageItems = menuData.filter((item) => item.Type === "Beverage");
  const otherItems = menuData.filter((item) => item.Type === "Other");

  const deleteAllMenu = async () => {
    const isConfirmed = window.confirm("Are you sure you want to delete all menu items? This action cannot be undone.");

    if (!isConfirmed) {
      return; // Exit if the user cancels
    }

    try {
      await axios.post(`${Url}/deleteallmenu`, { token, outlet: selectedOutlet });
      setMenuData([]); // Clear the menu data from the state
    } catch (error) {
      console.error("Error deleting menu items:", error);
    }
  };


  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="w-screen px-6 pt-6 overflow-x-auto overflow-y-auto flex-grow">
        <div className="flex justify-center text-center mb-8">
          <SelectSearchInput
            token={token}
            setToken={setToken}
            setSelectedOutlet={setSelectedOutlet}
            selectedOutlet={selectedOutlet}
            setCompany={setCompany}
          />
        </div>

        {/* <div className="flex justify-start gap-5 pb-6">
          <button
            onClick={() => navigate("/fileupload")}
            className="bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700"
          >
            Upload Menu Data
          </button>

          <button
            onClick={() => navigate("/editmenudata")}
            className="bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600"
          >
            Edit Menu Data
          </button>

          <button
            onClick={deleteAllMenu}
            className="bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700"
          >
            Delete All
          </button>
        </div> */}
        <div>

          {/* Show loading or error */}
          {loading ? (
            <p></p>
          ) : menuData.length === 0 ? (
            <p className="text-center text-xl text-red-500">No menu available</p>
          ) : (
            <div className="flex justify-between gap-6">
              {/* Food Table */}
              <div className="w-1/3">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">
                  Food
                </h2>
                {foodItems.length > 0 ? (
                  foodItems.map((category, index) => (
                    <div key={index} className="mb-6">
                      <h3 className="text-2xl font-semibold text-gray-600 mb-4">
                        {category.Description}
                      </h3>
                      {/* Loop through food subcategories */}
                      {category.items &&
                        category.items.length > 0 &&
                        category.items.map((subCategory, subIndex) => (
                          <div key={subIndex} className="mb-4">
                            <h4 className="text-xl font-semibold text-gray-600 mb-2">
                              {subCategory.Description}
                            </h4>
                            {/* Render subcategory items */}
                            {subCategory.items &&
                              subCategory.items.length > 0 &&
                              renderMenuItems(subCategory.items)}
                          </div>
                        ))}
                    </div>
                  ))
                ) : (
                  <p>No food items available</p>
                )}
              </div>

              {/* Beverage Table */}
              <div className="w-1/3">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">
                  Beverage
                </h2>
                {beverageItems.length > 0 ? (
                  beverageItems.map((category, index) => (
                    <div key={index} className="mb-6">
                      <h3 className="text-2xl font-semibold text-gray-600 mb-4">
                        {category.Description}
                      </h3>
                      {/* Loop through beverage subcategories */}
                      {category.items &&
                        category.items.length > 0 &&
                        category.items.map((subCategory, subIndex) => (
                          <div key={subIndex} className="mb-4">
                            <h4 className="text-xl font-semibold text-gray-600 mb-2">
                              {subCategory.Description}
                            </h4>
                            {/* Render subcategory items */}
                            {subCategory.items &&
                              subCategory.items.length > 0 &&
                              renderMenuItems(subCategory.items)}
                          </div>
                        ))}
                    </div>
                  ))
                ) : (
                  <p>No beverage items available</p>
                )}
              </div>

              {/* Others Table */}
              <div className="w-1/3 ml-1">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">
                  Others
                </h2>
                {otherItems.length > 0 ? (
                  otherItems.map((category, index) => (
                    <div key={index} className="mb-6">
                      <h3 className="text-2xl font-semibold text-gray-600 mb-4">
                        {category.Description}
                      </h3>
                      {/* Loop through others subcategories */}
                      {category.items &&
                        category.items.length > 0 &&
                        category.items.map((subCategory, subIndex) => (
                          <div key={subIndex} className="mb-4">
                            <h4 className="text-xl font-semibold text-gray-600 mb-2">
                              {subCategory.Description}
                            </h4>
                            {/* Render subcategory items */}
                            {subCategory.items &&
                              subCategory.items.length > 0 &&
                              renderMenuItems(subCategory.items)}
                          </div>
                        ))}
                    </div>
                  ))
                ) : (
                  <p>No other items available</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MenuMaster;
