import React from "react";
import "../../scss/footer.scss";

const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  return (
    <>
      <footer className="footer">
        {year}© SilverLine System Integrators Pvt. Ltd.
      </footer>
    </>
  );
};

export default Footer;
