import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../Navbar';
import SelectSearchInput from '../SelectSearch';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import DatePicker from "react-datepicker";
import Footer from '../Footer';

const DetailedPurchaseReport = () => {
    const [data, setData] = useState([]);
    const [date, setDate] = useState("");
    const [token, setToken] = useState("");
    const [outletSelected, setOutletSelected] = useState(""); // Empty initially
    const [company, setCompany] = useState(""); // Store company name
    const [error, setErrorMsg] = useState("");
    const tableRef = useRef(null);
    const [status, setStatus] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [aggregated, setAggregated] = useState("False"); // Default set to "False"

    const changeOutlet = (e, companyName) => {
        setOutletSelected(e);
        setCompany(companyName); // Update company name when outlet changes
    };

    const handleExport = () => {
        setStatus(true);
    };

    // Handle the search functionality
    const handleSearch = () => {
        const payload = {
            dateStart: startDate.toISOString().split('T')[0], // format as "yyyy-mm-dd"
            dateEnd: endDate.toISOString().split('T')[0],     // format as "yyyy-mm-dd"
            outlet: outletSelected,
            token: token,
            aggregated: aggregated, // Pass aggregated value as "True" or "False"
        };

        console.log("Sending payload:", payload);

        // Example of how you might send the data to your API using fetch (you can replace this with your own API call)
        fetch(`${process.env.REACT_APP_BASE_URL}/vendorwise-purchaseitem-lists`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => {
                setData(data); // Assuming the response contains the data to display
                setErrorMsg(""); // Clear error message if data is fetched successfully
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setErrorMsg("Failed to fetch data");
            });
    };

    const handleAggregatedChange = (e) => {
        const newAggregated = e.target.checked ? "True" : "False";
        setAggregated(newAggregated); // Update the aggregated value
    };

    // Fetch data when the page loads with the selected outlet and today's date
    useEffect(() => {
        if (outletSelected && token) {
            handleSearch(); // Fetch data with the default date range and selected outlet
        }
    }, [outletSelected, token]); // Re-fetch when outlet or token changes

    // Set today's date as default on page load if not already set
    useEffect(() => {
        setStartDate(new Date());
        setEndDate(new Date());
    }, []);

    // Fetch data whenever the aggregated value changes
    useEffect(() => {
        if (outletSelected && token) {
            handleSearch(); // Fetch data when the aggregated value changes
        }
    }, [aggregated, outletSelected, token]); // Added aggregated to the dependency array

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className='flex-grow'
                style={{
                    fontFamily: "Arial, sans-serif",
                    margin: "20px",
                    width: "60%",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}>
                <p style={{ textAlign: "center" }}>
                    <div className="flex justify-center text-center">
                        <SelectSearchInput
                            token={token}
                            setToken={setToken}
                            selectedOutlet={outletSelected}
                            setSelectedOutlet={changeOutlet}
                            setCompany={setCompany} // Pass setCompany to SelectSearchInput
                        />
                    </div>
                </p>

                {/* Search Input and Select Group */}
                <div className="w-2/3 mx-auto flex flex-col items-center justify-center m-4 p-4 border border-gray-300 rounded-lg shadow-md">
                    <label className="font-medium font-poppins md:text-base text-center mb-2">
                        Search Input
                    </label>
                    <div className="flex w-full justify-center gap-9 pt-3">
                        {/* Date Selection */}
                        <div className="relative flex flex-col items-center">
                            <label className="font-base font-poppins md:text-base">From:</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="yyyy-MM-dd"
                                className="w-20 md:w-24 text-xs md:text-base cursor-pointer text-center border border-gray-400 rounded-md p-1"
                                popperPlacement="bottom"
                                popperModifiers={[
                                    {
                                        name: 'offset',
                                        options: { offset: [0, 8] },
                                    },
                                    {
                                        name: 'zIndex',
                                        options: { zIndex: 9999 },
                                    },
                                ]}
                            />
                        </div>
                        <div className="relative flex flex-col items-center">
                            <label className="font-base font-poppins md:text-base">To:</label>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                dateFormat="yyyy-MM-dd"
                                className="w-20 md:w-24 text-xs md:text-base cursor-pointer text-center border border-gray-400 rounded-md p-1"
                                popperPlacement="bottom"
                                popperModifiers={[
                                    {
                                        name: 'offset',
                                        options: { offset: [0, 8] },
                                    },
                                    {
                                        name: 'zIndex',
                                        options: { zIndex: 9999 },
                                    },
                                ]}
                            />
                        </div>

                        {/* Aggregate Checkbox */}
                        <div className="relative flex flex-col items-center">
                            <label className="font-base font-poppins md:text-base">Aggregate:</label>
                            <input
                                type="checkbox"
                                checked={aggregated === "True"}
                                onChange={handleAggregatedChange}
                                className="form-checkbox"
                            />
                        </div>

                        {/* Search Button */}
                        <div className="relative flex flex-col items-center">
                            <button
                                onClick={handleSearch}
                                className="px-7 py-2 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg">
                                Search
                            </button>
                        </div>
                    </div>
                </div>

                <DownloadTableExcel
                    filename="detailedreport"
                    sheet="purchasereport"
                    currentTableRef={tableRef.current}>
                    <button className="export" onClick={handleExport}>
                        Export
                    </button>
                </DownloadTableExcel>

                {/* Show error message if there's an error */}
                {error ? (
                    <p style={{ textAlign: "center", color: "red" }}>{error}</p>
                ) : (
                    <div ref={tableRef}>
                        <table
                            style={{
                                width: "100%",
                                borderCollapse: "collapse",
                                marginTop: "20px",
                            }}>
                            <thead>
                                <th colSpan={aggregated === "False" ? 8 : 7}>
                                    <div className="flex flex-col justify-end">
                                        <h1
                                            style={{
                                                textAlign: "center",
                                                textDecoration: "underline",
                                            }}>
                                            {company}
                                        </h1>

                                        <h1
                                            style={{
                                                textAlign: "center",
                                            }}
                                            className="text-lg text-center font-medium font-poppins md:text-xl">
                                            {decodeURIComponent(outletSelected || "") || ""}
                                        </h1>

                                        <p
                                            style={{
                                                textAlign: "center",
                                                textDecoration: "underline",
                                            }}>
                                            Detailed Purchase Report From: {startDate.toLocaleDateString()} To: {endDate.toLocaleDateString()}
                                        </p>
                                    </div>
                                </th>
                                <tr>
                                    <th style={headerStyle}>Vendor Name</th>
                                    <th style={headerStyle}>Item Name</th>
                                    <th style={headerStyle}>Brand Name</th>
                                    <th style={headerStyle}>UOM</th>
                                    <th style={headerStyle}>Units Ordered</th>
                                    <th style={headerStyle}>Rate</th>
                                    <th style={headerStyle}>Total</th>
                                    {aggregated === "False" && <th style={headerStyle}>Purchase Bill Number</th>}
                                </tr>
                            </thead>

                            <tbody>
                                {data.map((vendor, vendorIndex) => (
                                    <React.Fragment key={vendorIndex}>
                                        <tr>
                                            <td
                                                style={{
                                                    fontWeight: "bold",
                                                    textTransform: "capitalize",
                                                    padding: "10px 5px",
                                                    borderBottom: "2px solid #010203",
                                                    fontSize: "15px",
                                                }}>
                                                {vendor.vendor}
                                            </td>
                                        </tr>

                                        {vendor.items.map((item, itemIndex) => (
                                            <tr key={itemIndex}>
                                                <td style={emptyCellStyle}></td>
                                                <td style={cellStyle}>{item.Name}</td>
                                                <td style={cellStyle}>{item.BrandName || "-"}</td>
                                                <td style={cellStyle}>{item.UOM}</td>
                                                <td style={cellStyle}>{item.ItemCount}</td>
                                                <td style={cellStyle}>{item.ItemRate}</td>
                                                <td style={cellStyle}>{item.Total}</td>
                                                {aggregated === "False" && <td style={cellStyle}>{item.purchaseBillNumber || "-"}</td>}
                                            </tr>
                                        ))}

                                        <tr>
                                            <td colSpan="6" style={emptyCellStyle}></td>
                                            <td style={{ ...cellStyle, fontWeight: "bold" }}>
                                                {vendor.total}
                                            </td>
                                            {aggregated === "False" && <td style={emptyCellStyle}></td>}
                                        </tr>
                                    </React.Fragment>
                                ))}

                                {data.length > 0 ? (
                                    <tr>
                                        <td colSpan="6" style={emptyCellStyle}></td>
                                        <td style={{ ...cellStyle, fontWeight: "bold" }}>
                                            {data
                                                .reduce((acc, vendor) => acc + vendor.items.reduce((sum, item) => sum + parseFloat(item.Total), 0), 0)
                                                .toFixed(2)}
                                        </td>
                                        {aggregated === "False" && <td style={emptyCellStyle}></td>}
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan={aggregated === "False" ? 8 : 7} style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
                                            {error || "No data available"}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default DetailedPurchaseReport;

const headerStyle = {
    padding: "5px",
    textAlign: "center",
    fontWeight: "bold",
    textDecoration: "underline",
    fontSize: "17px",
};

const cellStyle = {
    padding: "1px 1px",
    textAlign: "center",
    fontSize: "14px",
};

const emptyCellStyle = {
    padding: "1px 1px",
    textAlign: "center",
};
