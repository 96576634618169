import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

const DailyMonthlyForecast = ({ token, outlet }) => {
    const [fmdata, setFMData] = useState({});
    const [error, setError] = useState('');

    useEffect(() => {
        if (!token) return; // Prevent API call if token is not available

        const fetchMonthlyData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/getmonthlytrackforecast`,
                    { token }
                );
                setFMData(response.data.forecast || {});
            } catch (err) {
                setError("Failed to fetch monthly forecast data");
            }
        };
        fetchMonthlyData();
    }, [token]);

    const locations = Object.keys(fmdata);
    const labels = locations.length > 0 ? fmdata[locations[0]].map(entry => entry.date) : [];

    const datasets = locations.map((location, index) => ({
        label: location,
        data: fmdata[location].map(entry => entry.monthlyforecast),
        borderColor: ["#f67f86", "#74c69d", "#f9c74f", "#4a90e2", "#ff7f50"][index % 5],
        borderWidth: outlet === location ? 3 : 2,
        fill: false,
        tension: 0.4,
    }));

    const chartData = {
        labels,
        datasets,
    };

    const chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="pt-8">
            <h2 className="text-xl font-semibold mb-2 text-center">Sales Forecast by Outlet</h2>
            {error ? (
                <p className="text-red-500 text-center">{error}</p>
            ) : token && locations.length > 0 ? (
                <div className="w-full max-w-4xl h-[400px] mx-auto">
                    <Line data={chartData} options={chartOptions} />
                </div>
            ) : (
                <p className="text-center">Loading data...</p>
            )}
        </div>
    );
};

export default DailyMonthlyForecast;
