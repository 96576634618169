import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const WeeklyChart = ({ data }) => {
  // Check if data is valid
  if (!data || data.length === 0 || !data[0]?.yearly_data) {
    return <div>Data is unavailable or invalid</div>;
  }

  // Prepare the labels (days of the week)
  const labels = data[0]?.yearly_data?.dailylabel || []; // Fallback to empty array if not available

  // Utility function to check if a value is invalid (i.e., 0, null, "0.0", or 0.0)
  const isInvalidValue = (value) => {
    return value === 0 || value === null || value === "0.0" || value === 0.0;
  };

  // Prepare datasets for weekly data
  const datasets = data.map((yearData, index) => {
    // Define dark colors for red, blue, and green
    let borderColor = "";
    let backgroundColor = "";

    switch (index) {
      case 0:
        borderColor = "rgba(139, 0, 0, 1)"; // Dark red for the first dataset
        backgroundColor = "rgba(139, 0, 0, 0.2)"; // Light red background
        break;
      case 1:
        borderColor = "rgba(0, 0, 139, 1)"; // Dark blue for the second dataset
        backgroundColor = "rgba(0, 0, 139, 0.2)"; // Light blue background
        break;
      case 2:
        borderColor = "rgba(0, 128, 0, 1)"; // Dark green for the third dataset
        backgroundColor = "rgba(0, 128, 0, 0.2)"; // Light green background
        break;
      default:
        borderColor = "rgba(0, 0, 0, 1)"; // Default black for any additional datasets
        backgroundColor = "rgba(0, 0, 0, 0.2)"; // Default black background
        break;
    }

    // Replace invalid values with null for the specific day
    const filteredData =
      yearData?.yearly_data?.dailytotal?.map((total) =>
        isInvalidValue(total) ? null : total
      ) || []; // Fallback to empty array if data is unavailable

    return {
      id: index + 1,
      label: `Year ${yearData.year}`,
      data: filteredData, // Use filtered data
      borderColor: borderColor,
      backgroundColor: backgroundColor,
      fill: false,
    };
  });

  return (
    <div className="chart-container">
      <h3 className="text-2xl text-center pb-4">Weekly Comparison</h3>
      <Line
        className="line-chart"
        datasetIdKey="id"
        data={{
          labels: labels, // Set the days of the week as labels
          datasets: datasets, // Set the prepared datasets for each year
        }}
      />
    </div>
  );
};

export default WeeklyChart;
