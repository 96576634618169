import React, { useRef, useState } from 'react'
import BillDetail from '../Modal/BillDetail';
import DateFormat from './DateFormat';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';

const CustomerHistory = ({ customerHistory, discountTotal, totalSum, selectedOutlet }) => {
    let url = process.env.REACT_APP_BASE_URL;
    const tableRef = useRef(null);

    let navigate = useNavigate();
    const [token, setToken] = useState("");
    const [billInfo, setBillInfo] = useState({});
    const [billInfoList, setBillInfoList] = useState([])
    const [status, setStatus] = useState(false);

    const handleExport = () => {
        setStatus(true);
    };

    useEffect(() => {
        let tokenCheck = localStorage.getItem("token");
        if (!tokenCheck) {
            navigate('/')
        } else {
            setToken(localStorage.getItem("token"))
        }
    }, [])

    const handleBillInfo = (bill, date) => {
        const convertDate = new Date(date).toISOString().substring(0, 10);
        axios.post(`${url}/billinfo`, {
            bill_no: `${bill}`,
            Date: `${convertDate}`,
            Outlet_Name: `${selectedOutlet}`,
            token: `${token}`
        })
            .then((response) => {
                if (response?.data) {
                    // console.log(response?.data)
                    setBillInfoList(response.data.details)
                    setBillInfo(response.data)
                }
            })
            .catch((error) => {
                // console.log(error)
            })
    }
    return (
        <div className="table-customer-history">
            <DownloadTableExcel
                filename="customer_portal"
                sheet="CustomerHistory"
                currentTableRef={tableRef.current}>
                <button className="export" onClick={handleExport}>
                    Export
                </button>
            </DownloadTableExcel>
            <div className='customer-table-title'>
                <h5>Customer History</h5>
            </div>
            <div className="table-history-responsive table-data-customer">
                <table className="table-customer" ref={tableRef}>
                    <thead>
                        <tr className='position-sticky'>
                            <th>Bill</th>
                            <th>Customer</th>
                            <th>Date</th>
                            <th>Discount Amount</th>
                            <th>Discount Type</th>
                            <th>Mode</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    {customerHistory.map((item) => (
                        <tr>
                            <td ><button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleBillInfo(item.Bill, item.Date)}>{item.Bill}</button></td>
                            <td>{item.Customer}</td>
                            <td>
                                <DateFormat date={item.Date} /></td>
                            <td>{item.DiscountAmt}</td>
                            <td>{item.DiscountType}</td>
                            <td>{item.Mode}</td>
                            <td>{item.Total}</td>
                        </tr>
                    ))
                    }
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Discount: <span>{discountTotal}</span> </td>
                            <td></td>
                            <td></td>
                            <td>Total: {totalSum}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <BillDetail billInfo={billInfo} billInfoList={billInfoList} selectedOutlet={selectedOutlet} />
        </div>
    )
}

export default CustomerHistory