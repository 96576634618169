import React, { useEffect, useState } from 'react'
import "../../scss/home.scss";
import "../../scss/button.scss";
import Order from './order';
import { useNavigate } from 'react-router-dom';
import NavbarHome from '../Navbar/NavbarHome';
import Footer from '../Footer';

const Home = () => {
    const [token, setToken] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        let tokenCheck = localStorage.getItem("token");
        let typeCheck = localStorage.getItem("type")

        if (tokenCheck === null && !typeCheck) {
            navigate("/");
        } else {
            setToken(localStorage.getItem("token"));
        }
    }, [navigate]);

    const handleReport = () => {
        navigate('/report');
    };

    const handleItemReport = () => {
        navigate('/itemsreport');
    };

    return (
        <div className='min-h-screen flex flex-col'>
            <NavbarHome />
            <Order />
            <div className='btn-search-view gap-4'>
                <button
                    className="btn-search"
                    onClick={handleReport}
                >
                    Report
                </button>

                <button
                    className="btn-search"
                    onClick={handleItemReport}
                >
                    Item Report
                </button>
            </div>
            <Footer />
        </div>
    )
}

export default Home
