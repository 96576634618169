import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

// Registering chart elements
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const MonthlySalesForecast = ({ monthlyData }) => {

    const chartData = {
        labels: monthlyData.map((item) => item.name), 
        datasets: [
            {
                label: "Actual Sales",
                data: monthlyData.map((item, index) => index < 6 ? item.actual_sales : null),
                borderColor: "#f67f86",
                borderWidth: 3,
                fill: false,
                tension: 0.4,
            },
            {
                label: "This Month Sales",
                data: monthlyData.map((item, index) => index === 6 ? item.actual_sales : null),
                borderColor: "#ef9b0f",
                borderWidth: 3,
                fill: false,
                tension: 0.4,
                pointBackgroundColor: "#ffba00",
                pointBorderColor: "#ffba00",
                pointRadius: 4,
                pointHoverRadius: 5,
            },
            {
                label: "Forecast",
                data: monthlyData.map((item) => item.yhat),
                borderColor: "#8884d8",
                fill: false,
                tension: 0.4,
            },
            {
                label: "Lower Estimate",
                data: monthlyData.map((item, index) => (index > 5 ? item.yhat_lower : null)),
                borderColor: "#82ca9d",
                borderDash: [5, 5],
                fill: false,
                tension: 0.4,
            },
            {
                label: "Upper Estimate",
                data: monthlyData.map((item, index) => (index > 5 ? item.yhat_upper : null)),
                borderColor: "#ff7300",
                borderDash: [5, 5],
                fill: false,
                tension: 0.4,
            },
        ],
    };

    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="py-12">
            <h2 className="2xl:text-xl xl:text-lg font-semibold mb-2 text-center">Monthly Sales Prediction</h2>
            <div className="2xl:w-[750px] xl:w-[700px] 2xl:h-[450px] xl:h-[400px] mx-auto">
                <Line data={chartData} options={chartOptions} />
            </div>
        </div>
    );
};

export default MonthlySalesForecast;
