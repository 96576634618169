import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../Navbar/index";
import ComplimentaryTable from "./ComplimentaryTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import CustomerHistory from "./CustomerHistory";
import "../../scss/History/history.scss";
import "../../scss/History/datepicker.scss";
import '../../scss/customtable.scss';
import SelectSearchInput from "../SelectSearch/index";
import Footer from "../Footer";

const History = () => {
  let url = process.env.REACT_APP_BASE_URL;
  const [discountTotal, setDiscountTotal] = useState("");
  const [totalSum, setTotalSum] = useState("");
  const [customerHistory, setCustomerHistory] = useState([]);
  const [complimentary, setComplimentary] = useState([]);
  const [complimentaryTotal, setTotal] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [token, setToken] = useState("");
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [inputChange, setInputChange] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [noDataMessage, setNoDataMessage] = useState("");
  const [show, setShow] = useState(false);
  const [showCustomerHistory, setShowCustomerHistory] = useState(false);
  const [status, setStatus] = useState(false);

  let start = startDate.toISOString().slice(0, 10);
  let end = endDate.toISOString().slice(0, 10);
  let navigate = useNavigate();

  useEffect(() => {
    let tokenCheck = localStorage.getItem("token");
    let typeCheck = localStorage.getItem("type")

    if (tokenCheck === null && typeCheck === 'kitchen') {
      navigate("/");
    } else {
      setToken(localStorage.getItem("token"));
    }
  }, [navigate]);

  const handleExport = () => {
    setStatus(true);
  };

  const showComplimentary = () => {
    setErrorMessage("");
    setNoDataMessage("");

    axios
      .post(`${url}/customersaleshistory`, {
        start_date: start,
        end_date: end,
        uname: inputChange,
        token: token,
        Outlet_Name: selectedOutlet,
      })
      .then((response) => {
        if (response.data.details.length === 0) {
          setNoDataMessage("No data available for the provided customer name and date range.");
          setShowCustomerHistory(false); // Hide the customer history table
        } else {
          setShowCustomerHistory(true); // Show the customer history table
          setCustomerHistory(response.data.details);
          setDiscountTotal(response.data.DiscountTotal);
          setTotalSum(response.data.Total);
        }
      })
      .catch(() => {
        setShowCustomerHistory(false); // Hide the customer history table in case of an error
        setNoDataMessage("No data available. Please check your input.");
      });

    // Show data for complimentary
    axios
      .post(`${url}/complimentary`, {
        start_date: start,
        end_date: end,
        uname: inputChange,
        token: token,
        Outlet_Name: selectedOutlet,
      })
      .then((response) => {
        // If no complimentary data is available, show "No data available"
        if (response.data.details.length === 0) {
          setShow(false); // Hide the complimentary table
        } else {
          setShow(true); // Show the complimentary table
          setComplimentary(response.data.details);
          setTotal(response.data.Total);
        }
      })
      .catch(() => {
        setShow(false);
      });
  };

  const handleInputChange = (event) => {
    setInputChange(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log("Key pressed: ", event.key);
      showComplimentary();
    }
  };

  return (
    <div className="main-container">
      <Navbar />
      <div className="handle-date-input-btn">
        <div className="date-picker-style">
          <div>
            <div className="date-picker-start">
              <label className="date-picker-label">Start Date:</label>
              <DatePicker
                selected={startDate}
                dateFromat="YYYY-MM-DD"
                onChange={(date) => setStartDate(date)}
                className="date-picker"
              />
            </div>
          </div>
          <div className="date-picker-end">
            <label className="date-picker-label">End Date:</label>
            <DatePicker
              selected={endDate}
              dateFromat="YYYY-MM-DD"
              onChange={(date) => setEndDate(date)}
              className="date-picker"
            />
          </div>
        </div>
        <div className="btn-search-style">
          <SelectSearchInput
            token={token}
            setToken={setToken}
            setSelectedOutlet={setSelectedOutlet}
            selectedOutlet={selectedOutlet}
          />
          <div className="search-btn-div">
            <button onClick={showComplimentary} className="btn-show">
              Show
            </button>
          </div>
        </div>
        <div>
          <div className="input-customer-name">
            <label>Select Customer:</label>
            <div>
              <input
                type="text"
                placeholder="Customer Name"
                onChange={handleInputChange}
                value={inputChange}
                className="input-customer"
                onKeyDown={handleKeyPress}
              />
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-history">
        <h5>{selectedOutlet}</h5>

        {noDataMessage && <p className="no-data-message">{noDataMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div className={show ? "customer-complimentary-history" : "customer-history"}>
          {showCustomerHistory && (
            <CustomerHistory
              customerHistory={customerHistory}
              discountTotal={discountTotal}
              totalSum={totalSum}
              selectedOutlet={selectedOutlet}
              start={startDate}
              end={endDate}
            />
          )}

          {show && (
            <ComplimentaryTable
              complimentary={complimentary}
              complimentaryTotal={complimentaryTotal}
              start={startDate}
              end={endDate}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default History;
