import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../Navbar";
import Footer from "../Footer";
import * as XLSX from "xlsx";
import SalesLineChart from "./LinesChart";

const ReportTable = () => {
    const today = new Date().toISOString().split('T')[0];
    const [data, setData] = useState({
        dailyData: [],
        totalData: {},
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [selectedOutlet, setSelectedOutlet] = useState("");
    const [selectedCompany, setSelectedCompany] = useState("");
    const [tempSelectedCompany, setTempSelectedCompany] = useState("");
    const [outletOptions, setOutletOptions] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [fetchOnLoad, setFetchOnLoad] = useState(true);
    const [initialSelectedOutlet, setInitialSelectedOutlet] = useState("");
    const [initialStartDate, setInitialStartDate] = useState(today);
    const [initialEndDate, setInitialEndDate] = useState(today);
    const [displayedOutlet, setDisplayedOutlet] = useState("");

    useEffect(() => {
        const fetchOutlets = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/outlets`,
                    { token: "test" }
                );
                setOutletOptions(response.data);
                if (response.data.length > 0) {
                    setInitialSelectedOutlet(response.data[0].name);
                    setSelectedOutlet(response.data[0].name);
                    setTempSelectedCompany(response.data[0].company);
                    setSelectedCompany(response.data[0].company);
                }
            } catch (err) {
                setError("Failed to fetch outlets. Please try again.");
                console.error("Error fetching outlets:", err);
            }
        };
        fetchOutlets();
    }, []);

    useEffect(() => {
        if (fetchOnLoad && selectedOutlet) {
            fetchData();
            setFetchOnLoad(false);
        }
    }, [fetchOnLoad, selectedOutlet]);

    useEffect(() => {
        if (outletOptions && outletOptions.length > 0) {
            setDisplayedOutlet(outletOptions[0].name); // Set the first outlet as the default display
            setSelectedOutlet(outletOptions[0].name); // Set the first outlet as the selected outlet too
        }
    }, [outletOptions]);

    const handleOutletChange = (e) => {
        const selectedOutletName = e.target.value;
        setSelectedOutlet(selectedOutletName);
        const selectedOutletData = outletOptions.find(outlet => outlet.name === selectedOutletName);
        if (selectedOutletData) {
            setTempSelectedCompany(selectedOutletData.company);
        }
    };

    const fetchData = async () => {
        if (!selectedOutlet) {
            setError("Please select an outlet before searching.");
            return;
        }

        setLoading(true);
        setError(null);
        setIsDataFetched(false);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/dailyreport`,
                {
                    outlet: selectedOutlet,
                    dateStart: startDate,
                    dateEnd: endDate,
                    token: "test",
                }
            );

            setData({
                dailyData: response.data.daily_data,
                totalData: response.data.total_data,
            });

            setIsDataFetched(response.data.daily_data.length > 0);
        } catch (err) {
            setError("Failed to fetch data. Please try again.");
            console.error("Error fetching data:", err);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        if (!selectedOutlet) {
            setError("Please select an outlet before searching.");
            return;
        }
        setFetchOnLoad(false);
        fetchData(); // Fetch data using the currently selected outlet
        setSelectedCompany(tempSelectedCompany); // Update the company name for the search
        setInitialStartDate(startDate); // Update initial start date after the search
        setInitialEndDate(endDate);
        setDisplayedOutlet(selectedOutlet);
    };


    const exportToExcel = () => {
        // Define the column headers manually
        const headers = [
            "Date",
            "Total Sales",
            "VAT",
            "Net Sales",
            "Cash",
            "Mobile Payment",
            "Credit",
            "Credit Card",
            "Split",
            'Discount Amount',
            "Start Bill",
            "End Bill",
            "Complimentary",
            "NonChargeable",
            "Total Guests",
            "Food Sales",
            "Beverage Sales",
            "Other Sales"
        ];

        // Prepare the table data
        const exportData = data.dailyData.map((row) => [
            row.Date,
            row.TOTALSALES,
            row.TotalVat,
            row.netTOTALSALES,
            row.paymentStats?.Cash,
            row.paymentStats?.MobilePayment,
            row.paymentStats?.Credit,
            row.paymentStats?.CreditCard,
            row.paymentStats?.Split,
            row.DiscountAmt,
            row.StartBill,
            row.EndBill,
            row.paymentStats?.Complimentary,
            row.paymentStats?.NonChargeable,
            row.TotalGuests,
            row.Food_Sales,
            row.Beverage_Sales,
            row.Others_Sales || 0
        ]);

        // Add the Total row at the end
        exportData.push([
            "Total",
            data.totalData.TOTALSALES,
            data.totalData.TotalVat,
            data.totalData.netTOTALSALES,
            data.totalData.Cash,
            data.totalData.MobilePayment,
            data.totalData.Credit,
            data.totalData.CreditCard,
            data.totalData.Split,
            data.totalData.DiscountAmt,
            data.totalData.min_startbill,
            data.totalData.max_endbill,
            data.totalData.Complimentary,
            data.totalData.NonChargeable,
            data.totalData.TotalGuests,
            data.totalData.Food_Sales,
            data.totalData.Beverage_Sales,
            data.totalData.Others_Sales
        ]);

        // Prepare the header for the Excel file
        const header = [
            [selectedCompany || "Company Name"],
            [displayedOutlet || "Outlet Name"],
            [`Sales Report from ${initialStartDate} to ${initialEndDate}`],
            [], // Blank row for separation
            ...[headers], // Convert headers to array of arrays
            [], // Blank row for separation
        ];

        // Merge the header with the data
        const finalData = [...header, ...exportData];

        // Create the worksheet and workbook
        const ws = XLSX.utils.aoa_to_sheet(finalData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Report");

        // Write the file to disk
        XLSX.writeFile(wb, "sales_report.xlsx");
    };

    const formatCurrency = (value) => {
        if (!value || isNaN(value)) return "0.00"; // Convert null/undefined to "0.00"
        return new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className="flex-grow">
                <div style={{ textAlign: "center", marginBottom: "20px", paddingTop: '15px' }}>
                    <select
                        id="outlet"
                        value={selectedOutlet}  // Make sure this is updated dynamically
                        onChange={handleOutletChange} // Directly update the selected outlet here
                        style={{ marginLeft: "10px" }}
                    >
                        <option value="" disabled>Select an outlet</option>
                        {outletOptions.map((outlet, index) => (
                            <option key={index} value={outlet.name}>
                                {outlet.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                    <h3 style={{ fontSize: "18px", marginBottom: "10px" }}>
                        Select Date:
                    </h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <label htmlFor="startDate">Start Date: </label>
                        <input
                            type="date"
                            id="startDate"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            style={{ margin: "0 10px" }}
                        />

                        <label htmlFor="endDate">End Date: </label>
                        <input
                            type="date"
                            id="endDate"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            style={{ margin: "0 10px" }}
                        />

                        <button
                            onClick={handleSearch}
                            disabled={loading}
                            className="w-20 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg md:h-9"
                            style={{ marginLeft: "10px" }}
                        >
                            {loading ? "Loading..." : "Search"}
                        </button>
                    </div>
                </div>

                {/* Export Button Aligned to the Right */}
                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
                    <button
                        onClick={exportToExcel}
                        style={{
                            backgroundColor: "#4CAF50",
                            color: "white",
                            padding: "5px 15px",
                            borderRadius: "5px",
                            marginRight: "3rem"
                        }}
                    >
                        Export to Excel
                    </button>
                </div>

                {/* Company Name and Outlet Name Centered */}
                <div style={{ textAlign: "center", marginBottom: "10px" }}>
                    <h2 style={{ fontSize: "30px", fontWeight: "bold", textDecoration: "underline" }}>
                        {selectedCompany || "Company Name"}
                    </h2>
                    <h3 style={{ fontSize: "18px", fontWeight: "semi-bold" }}>
                        {displayedOutlet || "Outlet Name"} {/* Display only after search */}
                    </h3>
                    <h4 style={{ fontSize: "18px", fontStyle: "italic", marginTop: "5px" }}>
                        Sales Report from {initialStartDate} to {initialEndDate}
                    </h4>
                </div>

                {error && <p style={{ color: "red" }}>{error}</p>}

                {loading && <p>Loading...</p>}

                {!loading && !isDataFetched && <p className="text-center pt-4 text-lg font-semibold text-red-600">No Data Available!</p>}

                {isDataFetched && data.dailyData.length > 0 && (
                    <div
                        style={{
                            width: "100%",
                            overflowX: "auto",
                            marginTop: "20px",
                            border: "1px solid #ddd",
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <table
                            style={{
                                width: "100%",
                                minWidth: "1200px",
                                borderCollapse: "collapse",
                                textAlign: "center",
                                fontSize: '14px'
                            }}
                        >
                            <thead
                                style={{
                                    backgroundColor: "#f8f8f8",
                                    position: "sticky",
                                    top: 0,
                                }}
                            >
                                <tr>
                                    {[
                                        "Date", "Total Sales", "VAT", "Net Sales", "Cash",
                                        "Mobile Payment", "Credit", "Credit Card", "Split",
                                        "Discount Amount", "Start Bill", "End Bill",
                                        "Complimentary", "NonChargeable", "Total Guests",
                                        "Food Sales", "Beverage Sales", "Other Sales"
                                    ].map((header, index) => (
                                        <th
                                            key={index}
                                            style={{
                                                padding: "10px 7px",
                                                whiteSpace: "nowrap",
                                                backgroundColor: "#f8f8f8",
                                            }}
                                        >
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.dailyData.map((row, index) => (
                                    <tr key={index} style={{ borderBottom: "1px solid #ddd", }}>
                                        <td style={{ whiteSpace: "nowrap", padding: "3px 5px" }}>{row.Date}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.TOTALSALES)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.TotalVat)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.netTOTALSALES)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.paymentStats?.Cash)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.paymentStats?.MobilePayment)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.paymentStats?.Credit)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.paymentStats?.CreditCard)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.paymentStats?.Split)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.DiscountAmt)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{row.StartBill || "-"}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{row.EndBill || "-"}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.paymentStats?.Complimentary)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.paymentStats?.NonChargeable)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{row.TotalGuests}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.Food_Sales)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.Beverage_Sales)}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{formatCurrency(row.Others_Sales)}</td>
                                    </tr>
                                ))}
                            </tbody>

                            {/* Total Row */}
                            <tfoot>
                                <tr
                                    style={{
                                        fontWeight: "bold",
                                        backgroundColor: "#f8f8f8",
                                    }}
                                >
                                    {[
                                        "Total",
                                        formatCurrency(data.totalData.TOTALSALES),
                                        formatCurrency(data.totalData.TotalVat),
                                        formatCurrency(data.totalData.netTOTALSALES),
                                        formatCurrency(data.totalData.Cash),
                                        formatCurrency(data.totalData.MobilePayment),
                                        formatCurrency(data.totalData.Credit),
                                        formatCurrency(data.totalData.CreditCard),
                                        formatCurrency(data.totalData.Split),
                                        formatCurrency(data.totalData.DiscountAmt),
                                        (data.totalData.min_startbill || "-"),
                                        (data.totalData.max_endbill || "-"),
                                        formatCurrency(data.totalData.Complimentary),
                                        formatCurrency(data.totalData.NonChargeable),
                                        data.totalData.TotalGuests,
                                        formatCurrency(data.totalData.Food_Sales),
                                        formatCurrency(data.totalData.Beverage_Sales),
                                        formatCurrency(data.totalData.Others_Sales),
                                    ].map((value, index) => (
                                        <td
                                            key={index}
                                            style={{
                                                padding: "10px",
                                                whiteSpace: "nowrap",
                                                backgroundColor: "#f8f8f8",
                                            }}
                                        >
                                            {value}
                                        </td>
                                    ))}
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}

            </div>

            <SalesLineChart data={data} />;

            <Footer />
        </div>
    );
};

export default ReportTable;
