import React, { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import { formatSalesValue } from "../../utils/formatUtils";

// Registering chart elements and the annotation plugin
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin // Registering the annotation plugin
);

const DailyChart = ({ updatedForecastData, dateOffset }) => {
    const [showActualSalesAnnotation, setShowActualSalesAnnotation] = useState(true);
    const [showForecastAnnotation, setShowForecastAnnotation] = useState(false);
    const [showPastUpperLower, setShowPastUpperLower] = useState(false);
    // const [showUpperEstimate, setShowUpperEstimate] = useState(true);
    // const [showLowerEstimate, setShowLowerEstimate] = useState(true);

    const toggleActualSalesAnnotation = (e) => setShowActualSalesAnnotation(e.target.checked);
    const toggleForecastAnnotation = (e) => setShowForecastAnnotation(e.target.checked);
    const togglePastUpperLower = (e) => setShowPastUpperLower(e.target.checked);
    // const toggleUpperEstimate = (e) => setShowUpperEstimate(e.target.checked);
    // const toggleLowerEstimate = (e) => setShowLowerEstimate(e.target.checked);

    // Ensure data is in ascending order
    const sortedData = useMemo(() => {
        if (!updatedForecastData) return [];
        return [...updatedForecastData].sort((a, b) => new Date(a.ds) - new Date(b.ds));
    }, [updatedForecastData]);

    // Identify today's date and last actual sales
    const today = useMemo(() => new Date().toISOString().split("T")[0], []);
    const lastActualSales = useMemo(() => {
        const lastActualIndex = sortedData.findLastIndex(item => item.ds === today);
        return lastActualIndex !== -1 ? sortedData[lastActualIndex].actual_sales : 0;
    }, [sortedData, today]);

    const todaysForecast = useMemo(() => {
        const forecastIndex = sortedData.findIndex(item => item.ds === today);
        return forecastIndex !== -1 ? sortedData[forecastIndex].yhat : 0;
    }, [sortedData, today]);

    // Format date for display
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return ""; // Handle invalid dates

        // Check if the date is today's date
        const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
        if (dateString === today) return "-> Today <-"; // Return "Today" if the date matches

        const options = { weekday: 'short', day: 'numeric', month: 'short' };
        return date.toLocaleDateString('en-GB', options);
    };

    // Chart data
    const forecastChartData = useMemo(() => {
        const verticalLines = sortedData
            .filter(item => item.ds === today && item.actual_sales > 0) // Only for today
            .map(item => ({
                x: sortedData.findIndex((i) => i.ds === item.ds),
                y: item.actual_sales,
                label: `Actual Sales on ${formatDate(item.ds)}`,
            }));

        return {
            labels: sortedData.map(item => formatDate(item.ds)),
            datasets: [
                {
                    label: "Actual Sales",
                    data: sortedData.map(item => item.ds < today ? item.actual_sales || 0 : NaN),
                    borderColor: "#f67f86",
                    borderWidth: 3,
                    fill: false,
                    tension: 0.4,
                    pointBackgroundColor: sortedData.map(item => item.ds < today ? "#f67f86" : "#ff0000"),
                    pointBorderColor: sortedData.map(item => item.ds < today ? "#f67f86" : "#ff0000"),
                    pointRadius: sortedData.map(item => item.ds < today ? 4 : 6),
                    pointHoverRadius: sortedData.map(item => item.ds < today ? 5 : 7),
                },
                {
                    label: "Today's Sales",
                    data: sortedData.map(item => item.ds === today ? lastActualSales : NaN),
                    borderColor: "#ef9b0f",
                    borderWidth: 3,
                    fill: false,
                    tension: 0.4,
                    pointBackgroundColor: "#ffba00",
                    pointBorderColor: "#ffba00",
                    pointRadius: 4,
                    pointHoverRadius: 5,
                },
                {
                    label: "Forecast",
                    data: sortedData.map(item => item.yhat),
                    borderColor: "#8884d8",
                    fill: false,
                    tension: 0.4,
                },
                {
                    label: "Lower Estimate",
                    data: sortedData.map(item =>
                        item.ds < today && !showPastUpperLower ? NaN : item.yhat_lower
                    ),
                    borderColor: "#82ca9d",
                    borderDash: [5, 5],
                    fill: false,
                    tension: 0.4,
                },
                {
                    label: "Upper Estimate",
                    data: sortedData.map(item =>
                        item.ds < today && !showPastUpperLower ? NaN : item.yhat_upper
                    ),
                    borderColor: "#ff7300",
                    borderDash: [5, 5],
                    fill: false,
                    tension: 0.4,
                },
                {
                    label: "Events",
                    data: sortedData.map(item =>
                        item.event !== "" ? item.last_year_sales : NaN
                    ),
                    borderWidth: 0,
                    backgroundColor: "#006F6F",
                    pointRadius: sortedData.map(item =>
                        item.event !== null ? 5 : 0
                    ),
                    pointHoverRadius: 6,
                    fill: false,
                }
            ],
            verticalLines: verticalLines
        };
    }, [sortedData, today, lastActualSales, showPastUpperLower, dateOffset]);

    // Chart options with annotation plugin for vertical lines
    const chartOptions = useMemo(() => ({
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    callback: function (value, index, values) {
                        return this.getLabelForValue(value);
                    },
                    font: (context) => {
                        const index = context.index;
                        return {
                            size: sortedData[index]?.ds === today ? 15 : 12,
                            weight: sortedData[index]?.ds === today ? "bold" : "normal"
                        };
                    },
                },
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        const datasetLabel = tooltipItems[0].dataset.label;
                        if (datasetLabel === "Events") {
                            const item = sortedData[tooltipItems[0].dataIndex];
                            return item.event ? `${item.event}` : "";
                        }
                        return tooltipItems[0].dataset.label || "";
                    },
                    label: (tooltipItem) => {
                        const datasetLabel = tooltipItem.dataset.label;
                        if (datasetLabel === "Events") {
                            const lastYearSales = sortedData[tooltipItem.dataIndex].last_year_sales;
                            return `Last Year Sales: ${lastYearSales.toFixed(2)}`;
                        }
                        return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                    },
                },
            },
            annotation: {
                annotations: [
                    // ...forecastChartData.verticalLines.map((line) => ({
                    //     type: "line",
                    //     xMin: line.x,
                    //     xMax: line.x,
                    //     yMin: 0,
                    //     yMax: line.y,
                    //     borderColor: '#ffba00',
                    //     borderWidth: 3,
                    //     borderDash: [5, 5],
                    // })),
                    // {
                    //     type: 'line',
                    //     xMin: 0,
                    //     xMax: forecastChartData.verticalLines[0]?.x || 0,
                    //     yMin: lastActualSales,
                    //     yMax: lastActualSales,
                    //     borderColor: '#ffba00',
                    //     borderWidth: 3,
                    //     borderDash: [5, 5],
                    // },
                    // {
                    //     type: 'line',
                    //     xMin: 0,
                    //     xMax: sortedData.findIndex(item => item.ds === today),
                    //     yMin: todaysForecast,
                    //     yMax: todaysForecast,
                    //     borderColor: '#8884d8',
                    //     borderWidth: 3,
                    //     borderDash: [5, 5],
                    // },
                    // {
                    //     type: 'line',
                    //     xMin: sortedData.findIndex(item => item.ds === today),
                    //     xMax: sortedData.findIndex(item => item.ds === today),
                    //     yMin: lastActualSales,
                    //     yMax: todaysForecast,
                    //     borderColor: '#8884d8',
                    //     borderWidth: 3,
                    //     borderDash: [5, 5],
                    // },
                    ...(showActualSalesAnnotation ? [
                        // Use forecastChartData.verticalLines to map over the vertical lines and create line annotations
                        ...forecastChartData.verticalLines.map((line) => ({
                            type: "line",
                            xMin: line.x,
                            xMax: line.x,
                            yMin: 0,
                            yMax: line.y,
                            borderColor: '#ffba00',
                            borderWidth: 3,
                            borderDash: [5, 5],
                        })),
                        // Add another line for lastActualSales
                        {
                            type: 'line',
                            xMin: 0,
                            xMax: forecastChartData.verticalLines[0]?.x || 0,
                            yMin: lastActualSales,
                            yMax: lastActualSales,
                            borderColor: '#ffba00',
                            borderWidth: 3,
                            borderDash: [5, 5],
                        },
                    ] : []),
                    ...(showForecastAnnotation ? [
                        {
                            type: 'line',
                            xMin: 0,
                            xMax: sortedData.findIndex(item => item.ds === today),
                            yMin: todaysForecast,
                            yMax: todaysForecast,
                            borderColor: '#8884d8',
                            borderWidth: 3,
                            borderDash: [5, 5],
                        },
                        {
                            type: 'line',
                            xMin: sortedData.findIndex(item => item.ds === today),
                            xMax: sortedData.findIndex(item => item.ds === today),
                            yMin: 0,
                            yMax: todaysForecast,
                            borderColor: '#8884d8',
                            borderWidth: 3,
                            borderDash: [5, 5],
                        }
                    ] : []),
                    ...[
                        showActualSalesAnnotation && lastActualSales > 0 && {
                            type: 'label',
                            xValue: sortedData.findIndex(item => item.ds === today),
                            yValue: lastActualSales,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: 4,
                            borderWidth: 1,
                            borderColor: 'rgba(0, 0, 0, 0.2)',
                            color: '#ffba00',
                            font: { size: 12, weight: 'bold' },
                            padding: 5,
                            content: `Today Sales: ${formatSalesValue(lastActualSales)}`,
                            xAdjust: 0,
                            yAdjust: -18,
                        },
                        showForecastAnnotation && todaysForecast > 0 && {
                            type: 'label',
                            xValue: sortedData.findIndex(item => item.ds === today),
                            yValue: todaysForecast,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: 4,
                            borderWidth: 1,
                            borderColor: 'rgba(0, 0, 0, 0.2)',
                            color: '#8884d8',
                            font: { size: 12, weight: 'bold' },
                            padding: 5,
                            content: `Forecast: ${formatSalesValue(todaysForecast)}`,
                            xAdjust: 0,
                            yAdjust: -18,
                        }
                    ].filter(Boolean)
                ]
            },
        },
    }), [sortedData, forecastChartData.verticalLines, lastActualSales, todaysForecast, showActualSalesAnnotation, showForecastAnnotation]);


    return (
        <div className="flex flex-col">
            <div className="flex gap-4 mb-2 px-3 text-sm justify-between">
                {lastActualSales > 0 && (
                    <label className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            checked={showActualSalesAnnotation}
                            onChange={toggleActualSalesAnnotation}
                            className="rounded"
                        />
                        <span className="text-yellow-500">Show Actual Sales</span>
                    </label>
                )}
                {todaysForecast > 0 && (
                    <label className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            checked={showForecastAnnotation}
                            onChange={toggleForecastAnnotation}
                            className="rounded"
                        />
                        <span className="text-purple-800">Show Forecast</span>
                    </label>
                )}
                {sortedData.some(item => new Date(item.ds) < new Date(today)) && (
                    <label className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            checked={showPastUpperLower}
                            onChange={togglePastUpperLower}
                            className="rounded"
                        />
                        <span className="text-green-600">Show Upper/Lower Before Today</span>
                    </label>
                )}
            </div>
            <div className="2xl:w-[750px] h-[450px] xl:w-[700px]">
                <Line data={forecastChartData} options={chartOptions} />
            </div>
        </div>
    );
};

export default DailyChart;
