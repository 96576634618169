import React, { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import { formatSalesValue } from "../../utils/formatUtils";

// Registering chart elements and the annotation plugin
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin // Registering the annotation plugin
);

const DateRangeChart = ({ forecastData, today, islong }) => {
    const [showActualSalesAnnotation, setShowActualSalesAnnotation] = useState(true);
    const [showForecastAnnotation, setShowForecastAnnotation] = useState(true);
    const [showPastUpperLower, setShowPastUpperLower] = useState(false);

    const toggleActualSalesAnnotation = (e) => setShowActualSalesAnnotation(e.target.checked);
    const toggleForecastAnnotation = (e) => setShowForecastAnnotation(e.target.checked);
    const togglePastUpperLower = (e) => setShowPastUpperLower(e.target.checked);

    // Ensure forecastData is defined and is an array before processing
    const sortedData = useMemo(() => {
        if (!Array.isArray(forecastData) || forecastData.length === 0) {
            console.error("forecastData is invalid or empty");
            return []; // Return an empty array if data is invalid
        }
        return [...forecastData].sort((a, b) => new Date(a.ds) - new Date(b.ds));
    }, [forecastData]);

    // Identify last actual sales
    const lastActualSales = useMemo(() => {
        if (!sortedData.length) return 0;
        const lastActualIndex = sortedData.findLastIndex(item => item.ds === today);
        return lastActualIndex !== -1 ? sortedData[lastActualIndex].actual_sales : 0;
    }, [sortedData, today]);

    const todaysForecast = useMemo(() => {
        if (!sortedData.length) return 0;
        const forecastIndex = sortedData.findIndex(item => item.ds === today);
        return forecastIndex !== -1 ? sortedData[forecastIndex].yhat : 0;
    }, [sortedData, today]);

    // Format date for display
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return ""; // Handle invalid dates

        const options = { weekday: 'short', day: 'numeric', month: 'short' };
        const formattedDate = date.toLocaleDateString('en-GB', options);

        // Check if the date is today
        if (dateString === today) {
            return "Today"; // Replace with "Today" if the date is today
        }

        return formattedDate;
    };

    // Chart data
    const forecastChartData = useMemo(() => {
        if (!sortedData || sortedData.length === 0) return { labels: [], datasets: [] };  // Return empty data if no data available

        return {
            labels: sortedData.map(item => formatDate(item.ds)),
            datasets: [
                {
                    label: "Actual Sales",
                    data: sortedData.map(item => item.ds < today ? item.actual_sales || 0 : NaN),
                    borderColor: "#f67f86",
                    borderWidth: 3,
                    fill: false,
                    tension: 0.4,
                },
                {
                    label: "Today's Sales",
                    data: sortedData.map(item => item.ds === today ? lastActualSales : NaN),
                    borderColor: "#ef9b0f",
                    borderWidth: 3,
                    fill: false,
                    tension: 0.4,
                },
                {
                    label: "Forecast",
                    data: sortedData.map(item => item.yhat),
                    borderColor: "#8884d8",
                    fill: false,
                    tension: 0.4,
                },
                {
                    label: "Lower Estimate",
                    data: sortedData.map(item =>
                        item.ds < today && !showPastUpperLower ? NaN : item.yhat_lower
                    ),
                    borderColor: "#82ca9d",
                    borderDash: [5, 5],
                    fill: false,
                    tension: 0.4,
                },
                {
                    label: "Upper Estimate",
                    data: sortedData.map(item =>
                        item.ds < today && !showPastUpperLower ? NaN : item.yhat_upper
                    ),
                    borderColor: "#ff7300",
                    borderDash: [5, 5],
                    fill: false,
                    tension: 0.4,
                },
                {
                    label: "Events",
                    data: sortedData.map(item =>
                        item.event !== "" ? item.last_year_sales : NaN
                    ),
                    borderWidth: 0,
                    backgroundColor: "#006F6F",
                    pointRadius: sortedData.map(item =>
                        item.event !== null ? 5 : 0
                    ),
                    pointHoverRadius: 6,
                    fill: false,
                }
            ]
        };
    }, [sortedData, today, lastActualSales, showPastUpperLower]);

    // Calculate chart size dynamically based on number of data points
    const chartSize = useMemo(() => {
        const dataLength = sortedData.length;
        const defaultWidth = 750;
        let defaultHeight = 350; 
        
        let maxWidth = 1500; // Default max width for large screens
        if (islong) {
            maxWidth = 1300
            defaultHeight = 300
        } else if (window.innerWidth >= 1536) {
            maxWidth = 1500; // 2xl screens
        } else if (window.innerWidth >= 1280) {
            maxWidth = 1100; // xl screens
        } else {
            maxWidth = defaultWidth; // Default width for smaller screens
        }

        const aspectRatio = defaultWidth / defaultHeight;

        const width = Math.min(maxWidth, Math.max(defaultWidth, dataLength * 40));
        const height = width / aspectRatio;

        return { width, height };
    }, [sortedData]);

    const chartOptions = useMemo(() => {
        return {
            maintainAspectRatio: false,
            scales: {
                x: {
                    ticks: {
                        callback: function (value, index, values) {
                            const label = this.getLabelForValue(value);

                            // Check if the current date matches today's date
                            const currentDate = sortedData[index]?.ds;
                            if (currentDate === today) {
                                return 'Today'; // Display "Today" label
                            }
                            return label;
                        },
                        font: (context) => {
                            const index = context.index;
                            const currentDate = sortedData[index]?.ds;
                            const isToday = currentDate === today;

                            return {
                                size: isToday ? 15 : 12,
                                weight: isToday ? "bold" : "normal",
                                color: isToday ? "red" : "#000000",
                            };
                        },
                        // color: function (context) {
                        //     const index = context.index;
                        //     const currentDate = sortedData[index]?.ds;
                        //     if (currentDate === today) {
                        //         return 'red';
                        //     }
                        //     return '#000'; 
                        // }
                    },
                },
                y: {
                    beginAtZero: true,
                },
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (tooltipItems) => {
                            const datasetLabel = tooltipItems[0].dataset.label;
                            if (datasetLabel === "Events") {
                                const item = sortedData[tooltipItems[0].dataIndex];
                                return item.event ? `${item.event}` : "";
                            }
                            return tooltipItems[0].dataset.label || "";
                        },
                        label: (tooltipItem) => {
                            const datasetLabel = tooltipItem.dataset.label;
                            if (datasetLabel === "Events") {
                                const lastYearSales = sortedData[tooltipItem.dataIndex].last_year_sales;
                                return `Last Year Sales: ${lastYearSales.toFixed(2)}`;
                            }
                            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                        },
                    },
                },
                annotation: {
                    annotations: [
                        // Only add Actual Sales line if the checkbox is checked
                        showActualSalesAnnotation && lastActualSales > 0 && {
                            type: 'line',
                            xMin: sortedData.findIndex(item => item.ds === today),
                            xMax: sortedData.findIndex(item => item.ds === today),
                            yMin: 0,
                            yMax: lastActualSales,
                            borderColor: '#ffba00',
                            borderWidth: 3,
                            borderDash: [5, 5],
                        },
                        // Only add the line from X-axis to lastActualSales if the checkbox is checked
                        showActualSalesAnnotation && lastActualSales > 0 && {
                            type: 'line',
                            xMin: 0,
                            xMax: sortedData.findIndex(item => item.ds === today),
                            yMin: lastActualSales,
                            yMax: lastActualSales,
                            borderColor: '#ffba00',
                            borderWidth: 3,
                            borderDash: [5, 5],
                        },
                        // Only add Forecast line if the checkbox is checked
                        showForecastAnnotation && todaysForecast > 0 && {
                            type: 'line',
                            xMin: 0,
                            xMax: sortedData.findIndex(item => item.ds === today),
                            yMin: todaysForecast,
                            yMax: todaysForecast,
                            borderColor: '#8884d8',
                            borderWidth: 3,
                            borderDash: [5, 5],
                        },
                        // Add the line from Actual Sales to Forecast only if both checkboxes are checked
                        showForecastAnnotation && todaysForecast > 0 && {
                            type: 'line',
                            xMin: sortedData.findIndex(item => item.ds === today),
                            xMax: sortedData.findIndex(item => item.ds === today),
                            yMin: 0,
                            yMax: todaysForecast,
                            borderColor: '#8884d8',
                            borderWidth: 3,
                            borderDash: [5, 5],
                        },
                        showActualSalesAnnotation && lastActualSales > 0 && {
                            type: 'label',
                            xValue: sortedData.findIndex(item => item.ds === today),
                            yValue: lastActualSales,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: 4,
                            borderWidth: 1,
                            borderColor: 'rgba(0, 0, 0, 0.2)',
                            color: '#ffba00',
                            font: { size: 12, weight: 'bold' },
                            padding: 5,
                            content: `Todays Sales: ${formatSalesValue(lastActualSales)}`,
                            xAdjust: 0,
                            yAdjust: -18,
                        },
                        showForecastAnnotation && todaysForecast > 0 && {
                            type: 'label',
                            xValue: sortedData.findIndex(item => item.ds === today),
                            yValue: todaysForecast,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: 4,
                            borderWidth: 1,
                            borderColor: 'rgba(0, 0, 0, 0.2)',
                            color: '#8884d8',
                            font: { size: 12, weight: 'bold' },
                            padding: 5,
                            content: `Forecast: ${formatSalesValue(todaysForecast)}`,
                            xAdjust: 0,
                            yAdjust: -18,
                        },
                    ].filter(Boolean),
                },
            },
        };
    }, [
        sortedData,
        lastActualSales,
        todaysForecast,
        showActualSalesAnnotation,
        showForecastAnnotation,
    ]);


    return (
        <div className="flex flex-col">
            <div className="flex gap-4 mb-2 px-3 text-sm justify-between">
                {lastActualSales > 0 && (
                    <label className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            checked={showActualSalesAnnotation}
                            onChange={toggleActualSalesAnnotation}
                            className="rounded"
                        />
                        <span className="text-yellow-500">Show Actual Sales</span>
                    </label>
                )}
                {todaysForecast > 0 && (
                    <label className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            checked={showForecastAnnotation}
                            onChange={toggleForecastAnnotation}
                            className="rounded"
                        />
                        <span className="text-purple-800">Show Forecast</span>
                    </label>
                )}
                {sortedData.some(item => new Date(item.ds) < new Date(today)) && (
                    <label className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            checked={showPastUpperLower}
                            onChange={togglePastUpperLower}
                            className="rounded"
                        />
                        <span className="text-green-600">Show Upper/Lower Before Today</span>
                    </label>
                )}
            </div>
            <div style={{ width: chartSize.width, height: chartSize.height }}>
                <Line data={forecastChartData} options={chartOptions} />
            </div>
        </div>
    );
};

export default DateRangeChart;
