import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../scss/login.scss";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [errorValue, setErrorValue] = useState(false);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BASE_URL;

  // Handle login form submission
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${url}/login`, { username, password });
      const  token  = response.data.token;
      const type = response.data.type
      if (token && type !== 'kitchen') {
        localStorage.setItem("token", token);  
        localStorage.setItem("user", username);
        localStorage.setItem('type', type) 
        navigate("/bill"); // Redirect to the next page
      } else {
        localStorage.setItem("token", token);  
        localStorage.setItem("user", username);
        localStorage.setItem('type', type) 
        navigate("/home");
      }
    } catch (error) {
      setError(error.response?.data || {});
      setErrorValue(true);
    }
  };

  // Check if token exists and navigate accordingly
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/bill"); // Redirect if already logged in
    }
  }, [navigate]);

  return (
    <div className="bg-login">
      <div className="bg-form-img"></div>
      <form>
        <div className="img-logo">
          <img src="./logo.jpg" alt="logo" className="logo" />
        </div>

        <label>Username</label>
        <input
          type="text"
          placeholder="Email or Phone"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="username"
        />

        <label>Password</label>
        <input
          type="password"
          placeholder="Password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="password"
        />

        <button className="btn-login" onClick={handleLogin}>
          Log In
        </button>

        {errorValue && <p className="error">{error.error}</p>}
      </form>
    </div>
  );
};

export default Login;
