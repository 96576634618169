import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faReceipt } from "@fortawesome/free-solid-svg-icons";
import "../../scss/navbar.scss";
import { AiOutlineHistory } from "react-icons/ai";
import { BiCreditCardFront, BiTrendingUp } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { FiRefreshCcw } from "react-icons/fi";
import { IoPeopleOutline } from "react-icons/io5";
import {
  TbReportSearch,
  TbZoomMoney,
  TbTimeline,
  TbToolsKitchen,
  TbCalendarTime,
  TbUser,
  TbMenu2,
} from "react-icons/tb";
import { ImStatsDots } from "react-icons/im";
import { MdOutlineInventory } from "react-icons/md";

const Navbar = ({ arrow }) => {
  let navigate = useNavigate();

  // Handle logout
  const logoutUser = () => {
    localStorage.removeItem("type");
    localStorage.removeItem("token");
    localStorage.removeItem("outlet");
    navigate("/"); // Navigate to homepage upon logout
  };

  return (
    <nav className="navbar-new">
      <div className="top">
        <Link to="/bill">
          <div className="navbar-icons">
            <FontAwesomeIcon icon={faHome} className="nav-icon" />
            <label>Home</label>
          </div>
        </Link>
        <Link to="/salesreport">
          <div className="navbar-icons">
            <TbReportSearch className="nav-icon" />
            <label>Sales Report</label>
          </div>
        </Link>
        <Link to="/home">
          <div className="navbar-icons">
            <TbToolsKitchen className="nav-icon" />
            <label>Kitchen</label>
          </div>
        </Link>
        <Link to="/customerportal">
          <div className="navbar-icons">
            <AiOutlineHistory className="nav-icon" />
            <label>Customer Portal</label>
          </div>
        </Link>
        <Link to="/stats">
          <div className="navbar-icons">
            <ImStatsDots className="nav-icon" />
            <label>Stats</label>
          </div>
        </Link>
        <Link to="/timeline">
          <div className="navbar-icons">
            <TbTimeline className="nav-icon" />
            <label>Timeline</label>
          </div>
        </Link>
      </div>
      <div className="bottom">
        <Link to="/performance">
          <div className="navbar-icons">
            <TbCalendarTime className="nav-icon" />
            <label>Performance</label>
          </div>
        </Link>
        <Link to="/credit">
          <div className="navbar-icons">
            <BiCreditCardFront className="nav-icon" />
            <label>Credit Center</label>
          </div>
        </Link>
        <Link to="/inventory">
          <div className="navbar-icons">
            <MdOutlineInventory className="nav-icon" />
            <label>Inventory</label>
          </div>
        </Link>
        <Link to='/salespredict'>
          <div className="navbar-icons">
            <BiTrendingUp className="nav-icon" />
            <label>Sales Forecast</label>
          </div>
        </Link>
        <div className="navbar-icons" onClick={logoutUser}>
          <IoPeopleOutline className="nav-icon" />
          <label>Logout</label>
        </div>
        <Link to="/user">
          <div className="navbar-icons">
            <TbUser className="nav-icon" />
            <label>User</label>
          </div>
        </Link>
        <Link to="/menu">
          <div className="navbar-icons">
            <TbMenu2 className="nav-icon" />
            <label>Menu</label>
          </div>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
