import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../Navbar";
import Footer from "../Footer";

function EditMenu() {
    const [outlets, setOutlets] = useState([]);
    const [selectedOutlet, setSelectedOutlet] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [selectedDescription, setSelectedDescription] = useState("");
    const [menuData, setMenuData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editedItem, setEditedItem] = useState(null); // To track the item being edited
    const [newPrice, setNewPrice] = useState(""); // Editable price for item
    const [newDiscountExempt, setNewDiscountExempt] = useState(""); // Editable discountExempt for item

    const Url = process.env.REACT_APP_BASE_URL;

    // Fetch outlets on initial load
    useEffect(() => {
        const fetchOutlets = async () => {
            try {
                const response = await axios.post(`${Url}/outlets`, {
                    token: localStorage.getItem("token"),
                });
                setOutlets(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching outlets:", err);
                setError("Failed to fetch outlets");
                setLoading(false);
            }
        };
        fetchOutlets();
    }, [Url]);

    // Fetch menu data based on selected outlet
    useEffect(() => {
        if (!selectedOutlet) return;

        setLoading(true);
        axios
            .post(`${Url}/item-lists`, {
                token: localStorage.getItem("token"),
                outlet: selectedOutlet,
            })
            .then((response) => {
                setMenuData(response.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error("Error fetching menu items:", err);
                setError("Failed to fetch menu items");
                setLoading(false);
            });
    }, [selectedOutlet, Url]);

    // Handle delete item
    const handleDelete = async (itemName) => {
        try {
            const response = await axios.delete(
                `${Url}/updatemenuprice`, // Ensure the correct endpoint for deleting
                {
                    data: {  // The payload for the DELETE request
                        item_name: itemName,
                    },
                    headers: {
                        "Content-Type": "application/json", // Ensure the request content type is JSON
                    },
                }
            );

            // If the request was successful, update the state
            setMenuData((prevData) =>
                prevData.map((typeData) => ({
                    ...typeData,
                    items: typeData.items.filter((item) => item.Name !== itemName),
                }))
            );
            alert("Item deleted successfully.");
        } catch (err) {
            console.error("Error deleting item:", err);
            alert("Failed to delete item.");
        }
    };

    // Handle when the user clicks the Edit button
    const handleEdit = (item) => {
        setEditedItem(item); // Set the item to be edited
        setNewPrice(item.Price); // Set the editable price
        setNewDiscountExempt(item.discountExempt === "True" ? "True" : "False"); // Ensure it's set to "True" or "False" as string
    };

    // Handle saving the updated item
    const handleSaveEdit = () => {
        if (!newPrice || newDiscountExempt === "") {
            alert("Please fill in both price and discount exemption.");
            return;
        }

        // Send discountExempt as "True" or "False"
        const discountExemptValue = newDiscountExempt === "True" ? "True" : "False"; // Ensure the value is 'True' or 'False'

        // Sending the updated data to the backend
        axios
            .post(
                `${Url}/updatemenuprice`,
                {
                    item_name: editedItem.Name,
                    updated_price: newPrice,
                    discount_exempt: discountExemptValue, // Send "True" or "False" as a string
                },
                {
                    headers: {
                        "Content-Type": "application/json", // Ensure the request content type is JSON
                    },
                }
            )
            .then(() => {
                // Update the edited item in the state
                setMenuData((prevData) =>
                    prevData.map((typeData) => ({
                        ...typeData,
                        items: typeData.items.map((item) =>
                            item.Name === editedItem.Name
                                ? { ...item, Price: newPrice, discountExempt: discountExemptValue }
                                : item
                        ),
                    }))
                );
                setEditedItem(null); // Reset editing state
                setNewPrice(""); // Reset price input
                setNewDiscountExempt(""); // Reset discountExempt input
                alert("Item updated successfully.");
            })
            .catch((err) => {
                console.error("Error updating item:", err);
                alert("Failed to update item.");
            });
    };

    // Filter menu data by selected type and description
    const filteredMenuData = menuData
        .filter((typeData) => !selectedType || typeData.Type === selectedType)
        .map((typeData) => ({
            ...typeData,
            items: typeData.items.filter(
                (item) =>
                    !selectedDescription ||
                    item.Description === selectedDescription
            ),
        }))
        .filter((typeData) => typeData.items.length > 0);

    // Get all unique descriptions based on the selected type
    const uniqueDescriptions = selectedType
        ? menuData
            .filter((typeData) => typeData.Type === selectedType) // Filter by selected type
            .flatMap((typeData) =>
                typeData.items.map((item) => item.Description)
            )
        : [];

    const distinctDescriptions = [...new Set(uniqueDescriptions)];

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className="flex-grow">
                <div className="w-screen px-6 pt-6 overflow-x-auto overflow-y-auto flex-grow">
                    <h1 className="text-3xl font-semibold text-center mb-8">Edit Menu</h1>

                    {/* Outlet Selection */}
                    <div className="mb-4">
                        <label className="block text-lg font-medium">Select Outlet:</label>
                        <select
                            value={selectedOutlet}
                            onChange={(e) => setSelectedOutlet(e.target.value)}
                            className="mt-2 px-4 py-2 border rounded-md"
                        >
                            <option value="">Select an outlet</option>
                            {outlets.map((outlet) => (
                                <option key={outlet.value} value={outlet.value}>
                                    {outlet.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Type Selection */}
                    {selectedOutlet && (
                        <div className="mb-4">
                            <label className="block text-lg font-medium">Select Menu Type:</label>
                            <select
                                value={selectedType}
                                onChange={(e) => setSelectedType(e.target.value)}
                                className="mt-2 px-4 py-2 border rounded-md"
                            >
                                <option value="">Select a type</option>
                                {menuData.map((typeData) => (
                                    <option key={typeData.Type} value={typeData.Type}>
                                        {typeData.Type}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {/* Description Selection */}
                    {selectedType && (
                        <div className="mb-4">
                            <label className="block text-lg font-medium">Select Description:</label>
                            <select
                                value={selectedDescription}
                                onChange={(e) => setSelectedDescription(e.target.value)}
                                className="mt-2 px-4 py-2 border rounded-md"
                            >
                                <option value="">Select a description</option>
                                {distinctDescriptions.map((desc, index) => (
                                    <option key={index} value={desc}>
                                        {desc}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {/* Display Menu Data Based on Outlet, Type, and Description */}
                    {selectedOutlet && filteredMenuData.length > 0 && (
                        <div>
                            {filteredMenuData.map((typeData) => (
                                <div key={typeData.Type} className="mb-8">
                                    <h2 className="text-2xl font-bold">{typeData.Type}</h2>
                                    {typeData.items.map((descData) => (
                                        <div key={descData.Description} className="mb-6">
                                            <h3 className="text-xl font-semibold">{descData.Description}</h3>
                                            <table className="min-w-full bg-white border rounded-lg shadow-md mt-2">
                                                <thead className="bg-gray-200">
                                                    <tr>
                                                        <th className="w-1/4 px-4 py-2 text-center">Name</th>
                                                        <th className="w-1/4 px-4 py-2 text-center">Price</th>
                                                        <th className="w-1/4 px-4 py-2 text-center">State</th>
                                                        <th className="w-1/4 px-4 py-2 text-center">Discount Exempt</th>
                                                        <th className="w-1/4 px-4 py-2 text-center">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {descData.items.map((item, index) => (
                                                        <tr key={index} className="border-b hover:bg-gray-100">
                                                            <td className="w-1/4 px-4 py-2 text-center">{item.Name}</td>
                                                            <td className="w-1/4 px-4 py-2 text-center">Rs. {item.Price}</td>
                                                            <td className="w-1/4 px-4 py-2 text-center">{item.state}</td>
                                                            <td className="w-1/4 px-4 py-2 text-center">{item.discountExempt}</td>
                                                            <td className="w-1/4 px-4 py-2 text-center">
                                                                <div className="flex justify-center gap-4">
                                                                    <button
                                                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                                                        onClick={() => handleEdit(item)}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                    <button
                                                                        className="bg-red-500 text-white px-4 py-2 rounded"
                                                                        onClick={() => handleDelete(item.Name)}
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}

                    {editedItem && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white p-6 rounded-lg w-96">
                                <h3 className="text-2xl mb-4">Edit {editedItem.Name}</h3>
                                <div className="mb-4">
                                    <label className="block text-lg font-medium">Price:</label>
                                    <input
                                        type="number"
                                        value={newPrice}
                                        onChange={(e) => setNewPrice(e.target.value)}
                                        className="mt-2 px-4 py-2 border rounded-md w-full"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-lg font-medium">Discount Exempt:</label>
                                    <select
                                        value={newDiscountExempt} // Use the string "True" or "False"
                                        onChange={(e) => setNewDiscountExempt(e.target.value)} // Set the value directly to "True" or "False"
                                        className="mt-2 px-4 py-2 border rounded-md w-full"
                                    >
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </div>
                                <div className="flex justify-between">
                                    <button
                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                        onClick={handleSaveEdit}
                                    >
                                        Save
                                    </button>
                                    <button
                                        className="bg-gray-500 text-white px-4 py-2 rounded"
                                        onClick={() => setEditedItem(null)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default EditMenu;
