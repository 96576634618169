import React from 'react';

const PurchaseVolTable = ({ PurchaseVolTableinfo }) => {
  return (
    <div className="table-responsive-food -z-0">
      <table className="table-food">
        <thead className="table-background">
          <tr className="sticky-header bg-white">
            <th>Item</th>
            <th>Volume</th>
          </tr>
        </thead>
        {/* Wrap the mapped rows inside tbody */}
        <tbody>
          {PurchaseVolTableinfo.length > 0 ? (
            PurchaseVolTableinfo.map((item, index) => (
              <tr key={index}>
                <td>{item.Name}</td>
                <td>{item.purchase_total}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PurchaseVolTable;
