import React, { useEffect, useState } from 'react';
import Footer from '../../Footer';
import SelectSearchInput from '../../SelectSearch';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SelectSearch from 'react-select-search';
import './../../../scss/itemreport.scss'
import NavbarHome from '../../Navbar/NavbarHome';

const ItemReport = () => {
    const today = new Date().toISOString().split('T')[0];
    const navigate = useNavigate();

    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [selectedOutlet, setSelectedOutlet] = useState("");
    const [token, setToken] = useState("");
    const [error, setError] = useState(null);
    const [itemlist, setItemlist] = useState([]);
    const [selectedItem, setSelectedItem] = useState("");
    const [loading, setLoading] = useState(false);
    const [iData, setIData] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [searched, setSearched] = useState(false);
    const [showVoid, setShowVoid] = useState(true);
    const [showNonVoid, setShowNonVoid] = useState(true);

    useEffect(() => {
        const storedToken = localStorage.getItem("token");
        const storedOutlet = localStorage.getItem("outlet");

        if (!storedToken) {
            navigate("/");
        } else {
            setToken(storedToken);
            setSelectedOutlet(storedOutlet);
        }
    }, [navigate]);

    useEffect(() => {
        if (!selectedOutlet || !token) return;

        const fetchItems = async () => {
            try {
                const itemResponse = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/ordertrackeritem-lists`,
                    {
                        outlet: selectedOutlet,
                        token: token
                    }
                );

                const formattedItems = itemResponse.data.items.map((item, index) => ({
                    name: item,
                    value: item,
                }));

                setItemlist(formattedItems);
                setIsDisabled(false);
            } catch (err) {
                setError("Failed to fetch items list");
            }
        };
        fetchItems();
    }, [selectedOutlet, token]);

    const handleSearch = async (e) => {
        e.preventDefault();

        if (!selectedItem) {
            setError("Please select an item");
            return;
        }

        setLoading(true);
        setError(null);
        setSearched(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/items-orders-datewise`,
                {
                    startDate: startDate,
                    endDate: endDate,
                    outletName: selectedOutlet,
                    itemName: selectedItem
                }
            );

            const fetchedData = response.data.orders || [];
            // Apply the filter based on checkbox selections
            const filteredData = fetchedData.map(dateGroup => {
                const filteredOrders = dateGroup.orders.map(order => ({
                    ...order,
                    items: order.items.filter(item =>
                        (showVoid && item.completedAt === "VOID") ||
                        (showNonVoid && item.completedAt !== "VOID")
                    )
                })).filter(order => order.items.length > 0); // Remove empty orders after filtering

                return { ...dateGroup, orders: filteredOrders };
            }).filter(dateGroup => dateGroup.orders.length > 0); // Remove empty date groups after filtering

            setIData(filteredData);
        } catch (err) {
            setError("Failed to fetch item data");
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateStr) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateStr).toLocaleDateString(undefined, options);
    };

    return (
        <div className='min-h-screen flex flex-col'>
            <NavbarHome />
            <div className='flex-grow p-5'>
                <div className="flex justify-between text-base mx-40">
                    <div className="flex flex-col">
                        <label htmlFor="startDate" className="font-semibold">Start Date:</label>
                        <input
                            type="date"
                            id="startDate"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>

                    <div className="flex justify-center text-center mb-4">
                        <SelectSearchInput
                            token={token}
                            setToken={setToken}
                            selectedOutlet={selectedOutlet}
                            setSelectedOutlet={setSelectedOutlet}
                        />
                    </div>

                    <div className="flex flex-col">
                        <label htmlFor="endDate" className="font-semibold">End Date:</label>
                        <input
                            type="date"
                            id="endDate"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                </div>

                <div className='flex justify-center flex-col items-center'>
                    <SelectSearch
                        value={selectedItem}
                        search
                        placeholder="Select an item"
                        onChange={(value) => setSelectedItem(value)}
                        options={itemlist}
                        disabled={isDisabled}
                    />
                    <button
                        onClick={handleSearch}
                        disabled={loading}
                        className="border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg flex items-center justify-center px-4 py-2 mt-3"
                    >
                        {loading ? 'Loading...' : 'Search'}
                    </button>
                </div>



                <div className='flex justify-center'>
                    {loading && <p>Loading data...</p>}
                    {!loading && searched && iData.length === 0 && (
                        <p>No data available for the selected date range and item!</p>
                    )}

                    {searched && iData.length > 0 && (
                        <div>
                            <div className="flex justify-center mt-4">
                                <label className="mr-4">
                                    <input
                                        type="checkbox"
                                        checked={showVoid}
                                        onChange={() => setShowVoid(!showVoid)}
                                    />
                                    Show VOID Items
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={showNonVoid}
                                        onChange={() => setShowNonVoid(!showNonVoid)}
                                    />
                                    Show Non-VOID Items
                                </label>
                            </div>
                            <table
                                style={{
                                    borderCollapse: "collapse",
                                    marginTop: "20px",
                                    width: "auto",
                                    maxWidth: "100%",
                                    tableLayout: "auto",
                                }}>

                                <thead>
                                    <tr>
                                        <th className="table-header">Date</th>
                                        <th className="table-header">Ordered At</th>
                                        <th className="table-header">Completed At</th>
                                        <th className="table-header">Total Time</th>
                                        <th className="table-header">Avg Prep Time</th>
                                        <th className="table-header">Quantity</th>
                                        <th className="table-header">KOT ID</th>
                                        <th className="table-header">Table No.</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {iData.map((dateGroup, dateIndex) => {
                                        const filteredOrders = dateGroup.orders.map(order => ({
                                            ...order,
                                            items: order.items.filter(item =>
                                                (showVoid && item.completedAt === "VOID") ||
                                                (showNonVoid && item.completedAt !== "VOID")
                                            )
                                        })).filter(order => order.items.length > 0);

                                        if (filteredOrders.length === 0) return null; // Skip empty date groups

                                        return (
                                            <React.Fragment key={dateIndex}>
                                                <tr>
                                                    <td className="table-cell table-date" colSpan="1">
                                                        {formatDate(dateGroup.Date)}
                                                    </td>
                                                </tr>
                                                {filteredOrders.map((order, orderIndex) => (
                                                    <React.Fragment key={orderIndex}>
                                                        {order.items.map((item, itemIndex) => {
                                                            const isVoid = item.completedAt === "VOID";
                                                            return (
                                                                <tr key={itemIndex}>
                                                                    <td className="empty-cell"></td>
                                                                    <td className={`table-cell ${isVoid ? 'void-row' : ''}`}>{item.orderedAt}</td>
                                                                    <td className={`table-cell ${isVoid ? 'void-row' : ''}`}>{item.completedAt}</td>
                                                                    <td className={`table-cell ${isVoid ? 'void-row' : ''}`}>{item.TotalTime}</td>
                                                                    <td className={`table-cell ${isVoid ? 'void-row' : ''}`}>
                                                                        {item.AvgPrepTime === "00:00:00" ? "-" : item.AvgPrepTime}
                                                                    </td>
                                                                    <td className={`table-cell ${isVoid ? 'void-row' : ''}`}>{item.Quantity}</td>
                                                                    <td className={`table-cell ${isVoid ? 'void-row' : ''}`}>{order.KOTID}</td>
                                                                    <td className={`table-cell ${isVoid ? 'void-row' : ''}`}>{order.TableNum}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>

                            </table>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ItemReport;
