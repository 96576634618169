import axios from "axios";
import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import Navbar from "../Navbar";
import Selectitem from ".";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import SelectSearchInput from "../SelectSearch";
import Footer from "../Footer";

// Register the necessary components with ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ItemList = () => {
  const location = useLocation();
  const [token, setToken] = useState("");
  const [ItemSelected, setItemSelected] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState(false);
  const [itemdata, setItemData] = useState([]);
  const [department, setDepartment] = useState();
  const [taxable, setTaxable] = useState();
  const [UOM, setUOM] = useState();
  const [GroupName, setGroupName] = useState();
  const [StockType, setStockType] = useState();
  const [LatestItemSelected, setLatestItemSelected] = useState();
  const [OutletSelected, setOutletSelected] = useState(null);
  const [databar, setDataBar] = useState([]);
  const [databartotal, setDataBarTotal] = useState([]);
  const [datakitchen, setDataKitchen] = useState([]);
  const [datakitchentotal, setDataKitchenTotal] = useState([]);
  const [error, setError] = useState(null); // Added error state

  const Url = process.env.REACT_APP_BASE_URL;
  const reversedItemData = itemdata.slice().reverse();

  const handleItem = async () => {
    setStatus(true);
    try {
      const startFormatted = startDate.toISOString().slice(0, 10);
      const endFormatted = endDate.toISOString().slice(0, 10);

      const result = await axios.post(
        `${Url}/purchasefiltervendorwisefrompurchaseitem`,
        {
          token: token,
          item: ItemSelected,
          dateStart: startFormatted,
          dateEnd: endFormatted,
          outlet: OutletSelected,
        },
        setLatestItemSelected(ItemSelected)
      );

      if (result && result.data && result.data.data) {
        setItemData(result.data.data);
        setDepartment(result.data.data[0].Department);
        setGroupName(result.data.data[0].GroupName);
        setTaxable(result.data.data[0].Taxable);
        setUOM(result.data.data[0].UOM);
        setStockType(result.data.data[0].StockType);
        const barData = result.data.CostCenterIssuedData.find(
          (item) => item.CostCenter === "Bar"
        );
        const kitchenData = result.data.CostCenterIssuedData.find(
          (item) => item.CostCenter === "Kitchen"
        );

        if (barData && barData.ItemDetailsList) {
          setDataBar(barData.ItemDetailsList);
          setDataBarTotal(barData.grand_total);
        } else {
          setDataBar([]);
        }

        if (kitchenData && kitchenData.ItemDetailsList) {
          setDataKitchen(kitchenData.ItemDetailsList);
          setDataKitchenTotal(kitchenData.grand_total);
        } else {
          setDataKitchen([]);
        }

        // Clear error if data is successfully fetched
        setError(null);
      } else {
        setItemData([]);
        setDataBar([]);
        setDataKitchen([]);
        setError("No data found");
      }
    } catch (error) {
      console.log(error);
      setItemData([]);
      setError("Failed to fetch data. Please try again later.");
    }
  };

  const changeItem = (e) => {
    const ItemName = e;
    setItemSelected(ItemName);
    setSearchParams({
      firsttime: startDate.toISOString().slice(0, 10),
      secondtime: endDate.toISOString().slice(0, 10),
      ItemName: ItemName,
    });
  };
  const changeOutlet = (e) => {
    const OutletName = e;
    setOutletSelected(e);
    // setOutletSelected(encodeURIComponent(e));
    // setOutletSelected(decodeURIComponent(e));
    setSearchParams({
      firsttime: startDate.toISOString().slice(0, 10),
      secondtime: endDate.toISOString().slice(0, 10),
      OutletName: OutletName,
    });
  };

  const calculateGrandTotals = () => {
    let totalQty = 0;
    let totalAmount = 0;
    let count = itemdata.length;

    itemdata.forEach((element) => {
      totalQty += parseFloat(element.UnitsOrdered) || 0;
      totalAmount += parseFloat(element.Total) || 0;
    });

    return { count, totalQty, totalAmount };
  };

  const { count, totalQty, totalAmount } = calculateGrandTotals();

  const chartData = {
    labels: reversedItemData.map((item) =>
      new Date(item.ReceivedDate).toLocaleDateString("en-GB", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
    ),
    datasets: [
      {
        label: "Price vs Time Chart",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#94a3b8",
        data: reversedItemData.map((item) => item.Rate),
      },
    ],
  };
  const barchartdata = {
    labels: reversedItemData.map((item) =>
      new Date(item.ReceivedDate).toLocaleDateString("en-GB", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
    ),
    datasets: [
      {
        label: "Unit vs Time Chart",
        backgroundColor: "#334155",
        borderColor: "#334155",
        data: reversedItemData.map((item) => item.UnitsOrdered),
      },
    ],
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex flex-col flex-grow">
        <div className="w-full flex justify-center flex-col m-2">
          <div className="flex justify-center">
            <div className="flex justify-center w-auto mt-0">
              <div>
                <label className="font-base font-poppins md:text-base ">
                  From:
                </label>
                <DatePicker
                  selected={startDate}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setStartDate(date)}
                  className="w-16 md:w-24 text-xs md:text-base cursor-pointer"
                />
              </div>
              <div className="z-10">
                <label className="font-base font-poppins md:text-base">
                  To:
                </label>
                <DatePicker
                  selected={endDate}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setEndDate(date)}
                  className="w-16 md:w-24 text-xs md:text-base cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center text-center mt-2 -z-0">
            <SelectSearchInput
              token={token}
              setToken={setToken}
              selectedOutlet={OutletSelected}
              setSelectedOutlet={changeOutlet}
            />
            <Selectitem
              token={token}
              setToken={setToken}
              selectedItem={ItemSelected}
              setSelectedItem={changeItem}
            />
            <button
              className="  w-20 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg  md:h-9"
              onClick={handleItem}>
              Search
            </button>
          </div>
        </div>
        {status && (
          <div className="p-2 h-full md:h-[600px]">
            <div className="w-full h-full md:h-[500px] overflow-y-scroll overflow-x-auto hover:shadow mt-2">
              <div className="flex justify-start w-full h-2/4 space-x-4">
                {" "}
                <div className="flex flex-col justify-start ml-10 w-1/4 border-2 border-gray-300 p-4 rounded-lg">
                  <label className="font-serif font-bold">
                    Item Name: {LatestItemSelected}
                  </label>
                  <label className="font-serif font-bold">
                    Department: {department}
                  </label>
                  <label className="font-serif font-bold">
                    GroupName: {GroupName}
                  </label>
                  <label className="font-serif font-bold">
                    Stock Type: {StockType}
                  </label>
                  <label className="font-serif font-bold">
                    Taxable: {taxable}
                  </label>
                  <label className="font-serif font-bold">UOM: {UOM}</label>
                </div>
                <div className="flex justify-start w-3/4 ">
                  {" "}
                  <Line data={chartData} />
                  <Bar data={barchartdata} />
                </div>
              </div>

              <table className="table-auto w-full bg-opacity-75 overflow-auto rounded-2xl text-center relative font-poppins text-sm md:text-base ">
                <thead className="md:h-10 lg:sticky lg:top-0 bg-slate-50 ">
                  <tr className="mt-4 p-4 border-b-2 border-slate-400 md:my-5 ">
                    <th>S.N</th>
                    <th>Date</th>
                    <th>Vendor Name</th>
                    <th>UOM</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Total</th>
                    <th>GRN</th>
                  </tr>
                </thead>
                <tbody className="m-1 h-auto">
                  {itemdata.length > 0 ? (
                    itemdata.map((element, index) => (
                      <tr className="h-auto" key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {new Date(element.ReceivedDate).toLocaleDateString(
                            "en-GB",
                            {
                              weekday: "short",
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                        </td>
                        <td>{element.Company_Name}</td>
                        <td>{element.UOM}</td>
                        <td>{element.UnitsOrdered}</td>
                        <td>{element.Rate}</td>
                        <td>
                          {isNaN(parseFloat(element.Total))
                            ? "N/A"
                            : parseFloat(element.Total).toFixed(2)}
                        </td>

                        <td>{element.GRN}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No data available</td>
                    </tr>
                  )}
                  <tr className="w-full h-14  bg-white p-4 sticky bottom-0 -z-0">
                    <td className="font-bold">Count:{count}</td>
                    <td></td>
                    <td></td>
                    <td className="font-bold">Qty:{totalQty.toFixed(2)}</td>
                    <td></td>
                    <td className="font-bold">
                      Total:{totalAmount.toFixed(2)}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {status && (
          <div className="flex justify-around w-auto mt-0">
            {/* Display error message */}
            {error && (
              <div className="text-center text-red-500 mt-4">
                <strong>{error}</strong>
              </div>
            )}

            <div className="flex flex-col">
              {/* Kitchen Section */}
              <div className="flex flex-col">
                <div className="table-responsive-food">
                  <div className="table-responsive-food-header">
                    <div className="bg-heading-food">
                      <h4 className="food-heading">Kitchen</h4>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table-food">
                      <thead className="table-background">
                        <tr className="sticky-header bg-white">
                          <th>Group Name</th>
                          <th>Item Name</th>
                          <th>Brand Name</th>
                          <th>Amount</th>
                          <th>UOM</th>
                          <th>Rate</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datakitchen.length > 0 ? (
                          datakitchen.map((item, index) => (
                            <tr key={index}>
                              <td>{item.GroupName}</td>
                              <td>{item.ItemName}</td>
                              <td>{item.BrandName || " "}</td>
                              <td>{item.Amount}</td>
                              <td>{item.UOM}</td>
                              <td>{item.Rate}</td>
                              <td>{item.Total}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot className="w-full mt-1 bg-white p-2 sticky bottom-0 -z-0">
                        <tr className="p-2 h-14 mb-0">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>
                            Total:{" "}
                            <span className="text-base">
                              {datakitchentotal}
                            </span>
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* Bar Section */}
            <div className="flex flex-col">
              <div className="table-responsive-food">
                <div className="table-responsive-food-header">
                  <div className="bg-heading-food">
                    <h4 className="food-heading">Bar</h4>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table-food">
                    <thead className="table-background">
                      <tr className="sticky-header bg-white">
                        <th>Group Name</th>
                        <th>Item Name</th>
                        <th>Brand Name</th>
                        <th>Amount</th>
                        <th>UOM</th>
                        <th>Rate</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {databar.length > 0 ? (
                        databar.map((item, index) => (
                          <tr key={index}>
                            <td>{item.GroupName}</td>
                            <td>{item.ItemName}</td>
                            <td>{item.BrandName || " "}</td>
                            <td>{item.Amount}</td>
                            <td>{item.UOM}</td>
                            <td>{item.Rate}</td>
                            <td>{item.Total}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot className="w-full mt-1 bg-white p-4 sticky bottom-0 -z-0">
                      <tr className="p-4 h-14">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          Total:{" "}
                          <span className="text-base">{databartotal}</span>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ItemList;
