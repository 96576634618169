import React, { useState, useEffect } from "react";
import axios from "axios";
import SelectSearchInput from "../SelectSearch";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Footer from "../Footer";
import Navbar from "../Navbar";
import DailyChart from "./DailyChart";
import SalesForecastTable from "./SalesForecastTable";
import MonthlySalesForecast from "./MonthlySalesForecast";
import TodaysForecastTable from "./HighlightTable";
import UpcomingEvents from "./UpcomingEvents";
import { formatSalesValue } from '../../utils/formatUtils'
import "react-datepicker/dist/react-datepicker.css";
import SalesInDetail from "./SalesInDetail";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { format } from 'date-fns';
import DailyMonthlyForecast from "./DailyMonthlyForecast";

const PredictSales = () => {
    const today = new Date().toISOString().split('T')[0];

    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [forecastData, setForecastData] = useState([]);
    const [forecastEvent, setForecastEvent] = useState([]);
    const [monthlyData, setMonthlyData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedOutlet, setSelectedOutlet] = useState("");
    const [token, setToken] = useState("");
    const [dateOffset, setDateOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dailyData, setDailyData] = useState([]);
    const [yearMonthTodayData, setYearMonthTodayData] = useState({
        today: { yhat: 0 },
        month: { yhat: 0 },
        year: { yhat: 0 },
    });
    const [events, setEvents] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem("token");
        const storedOutlet = localStorage.getItem("outlet");

        if (!storedToken) {
            navigate("/");
        } else {
            setToken(storedToken);
            setSelectedOutlet(storedOutlet);
        }
    }, [navigate]);

    useEffect(() => {
        if (selectedOutlet) {
            setLoading(true);
            setDateOffset(0);
        }
    }, [selectedOutlet]);

    useEffect(() => {
        const fetchYearMonthTodaySalesForecast = async () => {
            if (selectedOutlet) {
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_BASE_URL}/getyearmonthtodaysaleforecast`,
                        { outlet_name: selectedOutlet },
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                    const data = response.data;
                    setYearMonthTodayData({
                        today: data.today[0],
                        month: data.month,
                        year: data.year,
                    });
                } catch (err) {
                    setError("Failed to fetch year/month/today sales forecast data");
                }
            }
        };

        fetchYearMonthTodaySalesForecast();
    }, [selectedOutlet, token]);

    useEffect(() => {
        const fetchDailyReport = async () => {
            if (selectedOutlet) {
                const date = new Date();
                const formattedDate = date.toISOString().split("T")[0];

                try {
                    const dailyResponse = await axios.post(
                        `${process.env.REACT_APP_BASE_URL}/dailyreport`,
                        {
                            outlet: selectedOutlet,
                            dateStart: formattedDate,
                            dateEnd: formattedDate,
                            token: token,
                        }
                    );
                    setDailyData(dailyResponse.data.daily_data);
                } catch (err) {
                    setError("Failed to fetch daily report data");
                }
            }
        };

        fetchDailyReport();
    }, [selectedOutlet, token]);

    useEffect(() => {
        if (!selectedOutlet) return;

        const fetchData = async () => {
            const date = new Date();
            date.setDate(date.getDate() + dateOffset);
            const formattedDate = date.toISOString().split("T")[0];

            try {
                // Fetching sales forecast data
                const forecastResponse = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/getsaleforecast`,
                    { outlet_name: selectedOutlet, date: formattedDate },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setForecastData(forecastResponse.data.data);
                setForecastEvent(forecastResponse.data.events);

                // Extract the start and end dates from the forecast data (assuming `ds` represents date)
                if (forecastResponse.data.data.length > 0) {
                    const startDate = forecastResponse.data.data[0].ds; // First item in the dataset
                    const endDate = forecastResponse.data.data[forecastResponse.data.data.length - 1].ds; // Last item in the dataset

                    setStartDate(startDate);
                    setEndDate(endDate);
                }

                // Fetching monthly data for sales forecast
                const monthlyResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/getmonthlysaleforecast`,
                    { outlet_name: selectedOutlet, date: formattedDate, type: 'Weekly' },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setMonthlyData(monthlyResponse.data.data);

            } catch (err) {
                setError("Failed to fetch data");
            } finally {
                setLoading(false);
            }
        };

        if (selectedOutlet) {
            fetchData();
        }
    }, [selectedOutlet, token, dateOffset]);


    const handleNextClick = () => {
        setDateOffset((prevOffset) => prevOffset + 14);
    };

    const handlePreviousClick = () => {
        setDateOffset((prevOffset) => prevOffset - 14);
    };

    const handleSearch = async () => {
        navigate("/forecastdetails", {
            state: { start: startDate, end: endDate },
        });
    }

    // Update actual sales values in the forecastData based on dailyData
    const updatedForecastData = forecastData.map((forecastItem) => {
        // Find the matching daily data item by date
        const dailyItem = dailyData.find(
            (item) => item.Date === forecastItem.ds
        );

        if (dailyItem) {
            // Update actual sales with the corresponding data from the daily report
            forecastItem.actual_sales = dailyItem.TOTALSALES || 0;
        }

        return forecastItem;
    });

    return (
        <div>
            <Navbar />
            <div className="relative">
                <div className="absolute left-0 top-0 2xl:p-6 xl:p-4">
                    <div className="mb-5">
                        <h2 className="text-lg font-semibold mb-2">Forecast Details:</h2>
                        <div className="flex flex-col space-y-4">
                            <div className="flex flex-col">
                                <label htmlFor="header1" className="font-semibold text-md">Today's Forecasted Sales:</label>
                                <input
                                    type="text"
                                    id="header1"
                                    className="border-2 border-gray-300 p-2 rounded-md bg-gray-100 w-[220px]"
                                    readOnly
                                    value={formatSalesValue(yearMonthTodayData.today.yhat.toFixed(2))}
                                />
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="header2" className="font-semibold text-md">
                                    This Month's Forecasted Sales:
                                </label>
                                <input
                                    type="text"
                                    id="header2"
                                    className={`border-2 border-gray-300 p-2 rounded-md bg-gray-100 w-[220px] font-semibold
                                    ${yearMonthTodayData.month.yhat > yearMonthTodayData.month.yesterday_monthlyforecast?.value
                                            ? "text-green-600"
                                            : "text-red-600"}`}
                                    readOnly
                                    value={formatSalesValue(yearMonthTodayData.month.yhat.toFixed(2))}
                                />
                            </div>

                            <table className="w-[290px] table-auto border-collapse text-md">
                                <tbody>
                                    {[
                                        { label: "First Day", key: "firstday_of_monthlyforecast", displayText: `Since ${format(new Date(), 'MMMM')}` },  // Dynamically get the current month
                                        { label: "One Week", key: "oneweek_monthlyforecast", displayText: "Since Last Week" },
                                        { label: "Yesterday", key: "yesterday_monthlyforecast", displayText: "Since Yesterday" },
                                    ].map(({ label, key, displayText }) => {
                                        const forecast = yearMonthTodayData.month?.[key];
                                        if (!forecast) return null;

                                        const difference = yearMonthTodayData.month.yhat - forecast.value;
                                        const percentageDiff = ((difference / yearMonthTodayData.month.yhat) * 100).toFixed(2);
                                        const isPositive = difference >= 0;
                                        const colorClass = isPositive ? "text-green-600" : "text-red-600";
                                        const sign = isPositive ? "+" : "-";
                                        const ArrowIcon = isPositive ? FaArrowUp : FaArrowDown;

                                        return (
                                            <tr key={key}>
                                                <td className="border-b p-2 font-semibold">{displayText}</td>
                                                <td className={`border-b p-2 font-semibold flex items-center gap-1 ${colorClass}`}>
                                                    {sign}{Math.abs(percentageDiff)}%
                                                    <ArrowIcon className="inline-block" />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                            <div className="flex flex-col">
                                <label htmlFor="header3" className="font-semibold text-md">This Year's Forecasted Sales:</label>
                                <input
                                    type="text"
                                    id="header3"
                                    className="border-2 border-gray-300 p-2 rounded-md bg-gray-100 w-[220px]"
                                    readOnly
                                    value={formatSalesValue(yearMonthTodayData.year.yhat.toFixed(2))}
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <UpcomingEvents events={events} selectedOutlet={selectedOutlet} allevents={forecastEvent} />
                    </div>

                </div>

                <div className="absolute top-0 right-0 p-6 flex flex-col">
                    {/* <h2 className="text-lg">Sales Details by Range:</h2>
                    <div className="flex justify-between pb-2 gap-3">
                        <div className="flex flex-col">
                            <label htmlFor="startDate" className="text-md font-semibold">Start Date: </label>
                            <input
                                type="date"
                                id="startDate"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                style={{ margin: "0" }}
                            />
                        </div>

                        <div className="flex flex-col">
                            <label htmlFor="endDate" className="text-md font-semibold">End Date: </label>
                            <input
                                type="date"
                                id="endDate"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                style={{ margin: "0" }}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center items-center pb-4">
                        <button
                            className="border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg flex items-center justify-center px-4 py-2"
                            onClick={handleSearch}
                            disabled={loading}
                        >
                            {loading ? "Loading..." : "Search"}
                        </button>
                    </div> */}

                    <TodaysForecastTable updatedForecastData={updatedForecastData} />
                    <SalesInDetail forecastData={updatedForecastData} compare={"compare"} />
                </div>
                <div className="2xl:max-w-4xl xl:max-w-3xl mx-auto p-6 xl:px-4 bg-white shadow-lg rounded-lg">
                    <div className="flex justify-around gap-10 px-4">
                        {/* <TodaysForecastTable left={'left'} updatedForecastData={updatedForecastData} /> */}
                        <div className="flex flex-col">
                            <label htmlFor="startDate" className="text-md font-semibold">Start Date: </label>
                            <input
                                type="date"
                                id="startDate"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                style={{ margin: "0" }}
                            />
                        </div>

                        <div className="flex justify-center text-center">
                            <SelectSearchInput
                                token={token}
                                setToken={setToken}
                                selectedOutlet={selectedOutlet}
                                setSelectedOutlet={setSelectedOutlet}
                            />
                        </div>

                        {/* <TodaysForecastTable right={'right'} updatedForecastData={updatedForecastData} /> */}
                        <div className="flex flex-col">
                            <label htmlFor="endDate" className="text-md font-semibold">End Date: </label>
                            <input
                                type="date"
                                id="endDate"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                style={{ margin: "0" }}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center items-center py-3">
                        <button
                            className="border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg flex items-center justify-center px-4 py-2"
                            onClick={handleSearch}
                            disabled={loading}
                        >
                            {loading ? "Loading..." : "Search"}
                        </button>
                    </div>

                    {error && <p className="text-red-600 font-semibold text-center">{error}</p>}

                    {/* <TodaysForecastTable updatedForecastData={updatedForecastData} /> */}

                    <h3 className="2xl:text-2xl xl:text-xl font-semibold text-center py-3">Sales Forecast</h3>
                    <div className="flex justify-center">
                        <DailyChart updatedForecastData={updatedForecastData} dateOffset={dateOffset} />
                    </div>
                    <div className="text-center mt-4 flex justify-between mb-5">
                        <button
                            onClick={handlePreviousClick}
                            className="border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg flex items-center justify-center px-4 py-2"
                            disabled={loading}
                        >
                            {loading ? (
                                "Loading..."
                            ) : (
                                <>
                                    <FaArrowLeft className="mr-2" />
                                    Previous
                                </>
                            )}
                        </button>
                        <button
                            type="button"
                            onClick={handleNextClick}
                            className="border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg flex items-center justify-center px-4 py-2"
                            disabled={loading}
                        >
                            {loading ? (
                                "Loading..."
                            ) : (
                                <>
                                    Next
                                    <FaArrowRight className="ml-2" />
                                </>
                            )}
                        </button>
                    </div>

                    <SalesForecastTable forecastData={updatedForecastData} />

                    <DailyMonthlyForecast token={token} outlet={selectedOutlet} />

                    <MonthlySalesForecast monthlyData={monthlyData} />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PredictSales;
