import React, { useEffect, useState } from "react";
import axios from "axios";

const UpcomingEvents = ({ selectedOutlet }) => {
    const [newEvent, setNewEvent] = useState({
        event: "",
        thisyeardate: "",
        lastyeardate: ""
    });
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState(null);
    const [noLastYearSales, setNoLastYearSales] = useState(false);
    const [manualSales, setManualSales] = useState("");
    const [showManualSalesInput, setShowManualSalesInput] = useState(false);
    const [hideButtons, setHideButtons] = useState(false); // Controls Add/Cancel button visibility
    const [skipLastYearCheck, setSkipLastYearCheck] = useState(false); // New state to control if last year check is skipped
    const [showAllEvents, setShowAllEvents] = useState(false)
    const [showDeleteOption, setShowDeleteOption] = useState(false)
    const [allEvents, setAllEvents] = useState([])
    const [events, setEvents] = useState([])

    const fetchEvents = async () => {
        if (selectedOutlet) {
            try {
                const eventResponse = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/getspecialevents`, { outlet: selectedOutlet }
                );
                setEvents(eventResponse.data.events);
            } catch (err) {
                setError("Failed to fetch daily report data");
            }
        }
    };

    useEffect(() => {
        if (!showModal) {
            fetchEvents();
        }
    }, [selectedOutlet, showModal]);

    const handleAddEventClick = () => {
        setNewEvent({ event: "", thisyeardate: "", lastyeardate: "" }); // Reset the form fields
        setError(null); // Reset error messages
        setNoLastYearSales(false); // Reset last year sales flag
        setShowManualSalesInput(false); // Reset manual sales input
        setHideButtons(false); // Ensure buttons are visible
        setShowModal(true);
    };

    const fetchForecast = async () => {
        const today = new Date().toISOString().split('T')[0];

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/getsaleforecast`,
                { outlet_name: selectedOutlet, date: today });
            setAllEvents(response.data.events);
        } catch (error) {
            setError("Failed to fetch data");
        }
    }

    const handleDeleteEventClick = async () => {
        setShowAllEvents(true)
        fetchForecast();
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEvent((prev) => ({ ...prev, [name]: value }));
    };

    const handleDeleteEventId = async (id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/deletespecialevents`,
                { specialevent_id: id },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            setAllEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));

        } catch (err) {
            setError("Failed to delete Event");
            return;
        }
    }

    const handleAddEventSubmit = async (e) => {
        e.preventDefault();

        // Validate that both "this year date" and "last year date" are filled
        if (!newEvent.event || !newEvent.thisyeardate || !newEvent.lastyeardate) {
            setError("All fields are required");
            return;
        }

        // Validate that "last year date" is one year before "this year date"
        const thisYearDate = new Date(newEvent.thisyeardate);
        const lastYearDate = new Date(newEvent.lastyeardate);
        const expectedLastYearDate = new Date(thisYearDate);
        expectedLastYearDate.setFullYear(thisYearDate.getFullYear() - 1);

        if (lastYearDate.getFullYear() !== expectedLastYearDate.getFullYear()) {
            setError("Last year date should be exactly one year before this year date.");
            return;
        }

        // Skip checking last year sales if the user opted for manual input
        if (!skipLastYearCheck) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/check-lastyearsales`,
                    { outlet_name: selectedOutlet, last_year_date: newEvent.lastyeardate },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (!response.data.exists) {
                    // If no last year sales data exists, show options to add sales manually
                    setNoLastYearSales(true);
                    setHideButtons(true); // Hide Add/Cancel buttons initially
                    return;
                }
            } catch (err) {
                setError("Failed to check last year sales data.");
                return;
            }
        }

        // Proceed to submit the event (either after last year sales check or manual input)
        try {
            // If manual input is provided, submit manual sales first
            if (showManualSalesInput && manualSales) {
                await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/post-lastyearsales`,
                    {
                        outlet_name: selectedOutlet,
                        last_year_date: newEvent.lastyeardate,
                        sales: manualSales,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
            }

            // After checking or adding manual sales, submit the event
            await axios.post(
                `${process.env.REACT_APP_BASE_URL}/posteventdata`, {
                event: newEvent.event,
                thisyeardate: newEvent.thisyeardate,
                lastyeardate: newEvent.lastyeardate,
                outlet: selectedOutlet
            },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            setShowModal(false);
            setNewEvent({ event: "", thisyeardate: "", lastyeardate: "" });
            setNoLastYearSales(false);
            setShowManualSalesInput(false);
            setHideButtons(false); // Show Add/Cancel buttons after event is added
        } catch (error) {
            setError("Failed to submit the event.");
        }
    };

    const handleManualSalesSubmit = async (e) => {
        e.preventDefault();

        if (!manualSales) {
            setError("Please enter last year's sales value");
            return;
        }

        try {
            await axios.post(
                `${process.env.REACT_APP_BASE_URL}/post-lastyearsales`,
                {
                    outlet_name: selectedOutlet,
                    last_year_date: newEvent.lastyeardate,
                    sales: manualSales,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            // After manually adding sales, submit the event
            await axios.post(
                `${process.env.REACT_APP_BASE_URL}/posteventdata`, {
                event: newEvent.event,
                thisyeardate: newEvent.thisyeardate,
                lastyeardate: newEvent.lastyeardate,
                outlet: selectedOutlet
            },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            setShowModal(false);
            setNewEvent({ event: "", thisyeardate: "", lastyeardate: "" });
            setNoLastYearSales(false);
            setShowManualSalesInput(false);
            setHideButtons(false); // Show Add/Cancel buttons after event is added
        } catch (error) {
            setError("Failed to submit last year sales data");
        }
    };

    return (
        <div>
            <h2 className="text-lg font-semibold mb-2">Upcoming Events</h2>
            <table className="min-w-full table-auto border-collapse text-sm">
                <thead>
                    <tr>
                        <th className="border-b p-2">Event</th>
                        <th className="border-b p-2">Last Year Date</th>
                        <th className="border-b p-2">This Year Date</th>
                    </tr>
                </thead>
                <tbody>
                    {events && events.length > 0 ? (
                        events.map((event, index) => (
                            <tr key={index}>
                                <td className="border-b p-2">{event.event}</td>
                                <td className="border-b p-2">
                                    {new Date(event.lastyeardate).toLocaleDateString()}
                                </td>
                                <td className="border-b p-2">
                                    {new Date(event.thisyeardate).toLocaleDateString()}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" className="border-b p-2 text-center">
                                No upcoming events
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className="flex gap-3">
                <button
                    onClick={handleAddEventClick}
                    className="border-2 border-slate-300 hover:bg-green-100 bg-green-700 text-zinc-100 hover:text-green-700 rounded-lg flex items-center justify-center px-3 py-2 mt-4 text-sm"
                >
                    Add Event
                </button>
                <button
                    onClick={handleDeleteEventClick}
                    className="border-2 border-slate-300 hover:bg-green-100 bg-red-700 text-zinc-100 hover:text-red-700 rounded-lg flex items-center justify-center px-3 py-2 mt-4 text-sm"
                >
                    Delete Event
                </button>

            </div>

            {showAllEvents && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg w-1/3">
                        <h3 className="text-lg font-semibold mb-4 text-center">All Events</h3>
                        <table className="min-w-full table-auto border-collapse text-sm">
                            <thead>
                                <tr>
                                    <th className="border-b p-2">Event</th>
                                    <th className="border-b p-2">Last Year Date</th>
                                    <th className="border-b p-2">This Year Date</th>
                                    <th className="border-b p-2">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allEvents && allEvents.length > 0 ? (
                                    allEvents.map((event, index) => (
                                        <tr key={index}>
                                            <td className="border-b p-2">{event.event}</td>
                                            <td className="border-b p-2">
                                                {new Date(event.lastyeardate).toLocaleDateString()}
                                            </td>
                                            <td className="border-b p-2">
                                                {new Date(event.thisyeardate).toLocaleDateString()}
                                            </td>
                                            <td className="border-b p-2">
                                                <button
                                                    type="button"
                                                    onClick={() => handleDeleteEventId(event.id)}
                                                    className="border-none text-red-700 "
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="border-b p-2 text-center">
                                            No events
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="flex flex-row-reverse pt-4">
                            <button
                                type="button"
                                onClick={() => {
                                    setShowAllEvents(false)
                                    fetchEvents();
                                }}
                                className="border-2 border-gray-300 hover:bg-red-100 bg-red-700 text-zinc-100 hover:text-red-700 rounded-lg px-4 py-2 text-sm"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg w-1/3">
                        <h3 className="text-lg font-semibold mb-4">Add New Event</h3>

                        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

                        <form onSubmit={handleAddEventSubmit}>
                            <div className="mb-4">
                                <label className="block font-semibold text-md">Event:</label>
                                <input
                                    type="text"
                                    name="event"
                                    value={newEvent.event}
                                    onChange={handleInputChange}
                                    className="border-2 border-gray-300 p-2 rounded-md w-full"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block font-semibold text-md">This Year Date:</label>
                                <input
                                    type="date"
                                    name="thisyeardate"
                                    value={newEvent.thisyeardate}
                                    onChange={handleInputChange}
                                    className="border-2 border-gray-300 p-2 rounded-md w-full"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block font-semibold text-md">Last Year Date:</label>
                                <input
                                    type="date"
                                    name="lastyeardate"
                                    value={newEvent.lastyeardate}
                                    onChange={handleInputChange}
                                    className="border-2 border-gray-300 p-2 rounded-md w-full"
                                    required
                                />
                            </div>

                            {noLastYearSales && !showManualSalesInput && (
                                <div className="mb-4">
                                    <p className="text-red-500">
                                        There's no last year sales data. Would you like to add data manually?
                                    </p>
                                    <button
                                        onClick={() => {
                                            setShowManualSalesInput(true);
                                            setSkipLastYearCheck(true); // Skip last year sales check after manual input
                                            setHideButtons(false); // Show Add/Cancel buttons after choosing "Yes"
                                        }}
                                        className="mr-2 px-3 py-1 bg-green-600 text-white rounded"
                                    >
                                        Yes
                                    </button>
                                    <button
                                        onClick={() => {
                                            setNoLastYearSales(false);
                                            setSkipLastYearCheck(true); // Skip last year sales check after choosing "No"
                                            setHideButtons(false); // Show Add/Cancel buttons after choosing "No"
                                        }}
                                        className="px-3 py-1 bg-gray-600 text-white rounded"
                                    >
                                        No
                                    </button>
                                </div>
                            )}

                            {showManualSalesInput && (
                                <div className="mb-4">
                                    <label className="block font-semibold text-md">Last Year Sales:</label>
                                    <input
                                        type="number"
                                        value={manualSales}
                                        onChange={(e) => setManualSales(e.target.value)}
                                        className="border-2 border-gray-300 p-2 rounded-md w-full"
                                    />
                                </div>
                            )}

                            {!hideButtons && (
                                <div className="flex justify-end space-x-4">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setShowModal(false)
                                            fetchEvents()
                                        }}
                                        className="border-2 border-gray-300 hover:bg-red-100 bg-red-700 text-zinc-100 hover:text-red-700 rounded-lg px-4 py-2 text-sm"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="border-2 border-slate-300 hover:bg-green-100 bg-green-700 text-zinc-100 hover:text-green-700 rounded-lg px-4 py-2 text-sm"
                                    >
                                        Add Event
                                    </button>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UpcomingEvents;
