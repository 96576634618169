import React from 'react';

const Detail = ({ information }) => {
    const { DineIn, DineIn_totalSales, TakeAway_totalSales, TakeAway, Voids, Cooked, cooking } = information;

    return (
        <div className='report-flex'>
            <div className='report-info-two 2xl:text-lg'>
                <div className='report-info'>
                    <label>Dine In:</label>
                    <span>{DineIn}</span>
                </div>
                <div className='report-info'>
                    <label>Dine In Sales:</label>
                    <span>{DineIn_totalSales}</span>
                </div>
                <div className='report-info'>
                    <label>Takeaway:</label>
                    <span>{TakeAway}</span>
                </div>
                <div className='report-info'>
                    <label>Takeaway Sales:</label>
                    <span>{TakeAway_totalSales}</span>
                </div>
                <div className='report-info'>
                    <label>Voids:</label>
                    <span>{Voids === "" || Voids === null ? 0 : Voids}</span>
                </div>
                <div className='report-info'>
                    <label>Cooked:</label>
                    <span>{Cooked}</span>
                </div>
                <div className='report-info'>
                    <label>Cooking:</label>
                    <span>{cooking}</span>
                </div>
            </div>
        </div>
    )
}
export default Detail