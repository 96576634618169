import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import axios from "axios";
import SelectSearchInput from "../SelectSearch";
import { useSearchParams } from "react-router-dom";
import { DatePicker } from "rsuite";
import Footer from "../Footer";

const CostCenter = () => {
  const Url = process.env.REACT_APP_BASE_URL;
  const [databar, setDataBar] = useState([]);
  const [databartotal, setDataBarTotal] = useState([]);
  const [datakitchen, setDataKitchen] = useState([]);
  const [datakitchentotal, setDataKitchenTotal] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [OutletSelected, setOutletSelected] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [token, setToken] = useState("");
  const [error, setError] = useState(null); // Added error state

  const handleCostCenter = async () => {
    // Clear previous data before fetching new data
    setDataBar([]);
    setDataBarTotal([]);
    setDataKitchen([]);
    setDataKitchenTotal([]);

    try {
      const startFormatted = startDate.toISOString().slice(0, 10);
      const endFormatted = endDate.toISOString().slice(0, 10);

      const response = await axios.post(
        `${Url}/costcenterwiseAllStockTransfers`,
        {
          outlet: OutletSelected,
          startDate: startFormatted,
          endDate: endFormatted,
        }
      );

      // Check for the response data
      if (response && response.data) {
        const barData = response.data.find((item) => item.CostCenter === "Bar");
        const kitchenData = response.data.find(
          (item) => item.CostCenter === "Kitchen"
        );

        if (barData && barData.ItemDetailsList) {
          setDataBar(barData.ItemDetailsList);
          setDataBarTotal(barData.grand_total);
        } else {
          setDataBar([]);
        }

        if (kitchenData && kitchenData.ItemDetailsList) {
          setDataKitchen(kitchenData.ItemDetailsList);
          setDataKitchenTotal(kitchenData.grand_total);
        } else {
          setDataKitchen([]);
        }

        // Clear error if data is successfully fetched
        setError(null);
      } else {
        setDataBar([]);
        setDataKitchen([]);
        setError("No data found");
      }
    } catch (error) {
      // Handle API errors
      console.log("Error fetching data:", error);
      setError("Failed to fetch data. Please try again later.");
    }
  };

  const changeOutlet = (e) => {
    const OutletName = e;
    setOutletSelected(OutletName);
    setSearchParams({
      firsttime: startDate.toISOString().slice(0, 10),
      secondtime: endDate.toISOString().slice(0, 10),
      OutletName: OutletName,
    });
  };

  // useEffect(() => {
  //   // Call handleCostCenter every time input changes
  //   handleCostCenter();
  // }, [startDate, endDate, OutletSelected]); // Re-run API call when these change

  return (
    <>
      <Navbar />
      <div className="flex justify-center w-auto mt-0">
        <div>
          <label className="font-base font-poppins md:text-base ">From:</label>
          <DatePicker
            selected={startDate}
            dateFormat="yyyy-mm-dd"
            onChange={(date) => setStartDate(date)}
            className="w-24 md:w-32 text-xs md:text-base cursor-pointer"
          />
        </div>
        <div className="z-10">
          <label className="font-base font-poppins md:text-base">To:</label>
          <DatePicker
            selected={endDate}
            dateFormat="yyyy-mm-dd"
            onChange={(date) => setEndDate(date)}
            className="w-24 md:w-32 text-xs md:text-base cursor-pointer"
          />
        </div>
      </div>
      <div className="flex justify-center text-center mt-2 -z-0">
        <div className="text-center">
          <SelectSearchInput
            token={token}
            setToken={setToken}
            selectedOutlet={OutletSelected}
            setSelectedOutlet={changeOutlet}
          />
        </div>
        <button
          className="w-20 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg md:h-9"
          onClick={handleCostCenter}>
          Search
        </button>
      </div>

      {/* Display error message */}
      {error && (
        <div className="text-center text-red-500 mt-4">
          <strong>{error}</strong>
        </div>
      )}

      <div className="flex flex-col">
        {/* Kitchen Section */}
        <div className="flex flex-col">
          <div className="table-responsive-food">
            <div className="table-responsive-food-header">
              <div className="bg-heading-food">
                <h4 className="food-heading">Kitchen</h4>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table-food">
                <thead className="table-background">
                  <tr className="sticky-header bg-white">
                    <th>Group Name</th>
                    <th>Item Name</th>
                    <th>Brand Name</th>
                    <th>Amount</th>
                    <th>UOM</th>
                    <th>Rate</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {datakitchen.length > 0 ? (
                    datakitchen.map((item, index) => (
                      <tr key={index}>
                        <td>{item.GroupName}</td>
                        <td>{item.ItemName}</td>
                        <td>{item.BrandName || " "}</td>
                        <td>{item.Amount}</td>
                        <td>{item.UOM}</td>
                        <td>{item.Rate}</td>
                        <td>{item.Total}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot className="w-full mt-1 bg-white p-2 sticky bottom-0 -z-0">
                  <tr className="p-2 h-14 mb-0">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      Total:{" "}
                      <span className="text-base">{datakitchentotal}</span>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        {/* Bar Section */}
        <div className="flex flex-col">
          <div className="table-responsive-food">
            <div className="table-responsive-food-header">
              <div className="bg-heading-food">
                <h4 className="food-heading">Bar</h4>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table-food">
                <thead className="table-background">
                  <tr className="sticky-header bg-white">
                    <th>Group Name</th>
                    <th>Item Name</th>
                    <th>Brand Name</th>
                    <th>Amount</th>
                    <th>UOM</th>
                    <th>Rate</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {databar.length > 0 ? (
                    databar.map((item, index) => (
                      <tr key={index}>
                        <td>{item.GroupName}</td>
                        <td>{item.ItemName}</td>
                        <td>{item.BrandName || " "}</td>
                        <td>{item.Amount}</td>
                        <td>{item.UOM}</td>
                        <td>{item.Rate}</td>
                        <td>{item.Total}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot className="w-full mt-1 bg-white p-4 sticky bottom-0 -z-0">
                  <tr className="p-4 h-14">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      Total: <span className="text-base">{databartotal}</span>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CostCenter;
