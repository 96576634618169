import React from 'react';

const TopItem = ({ TopIteminfo }) => {
  return (
    <div className="table-responsive-food -z-0">
      <table className="table-food">
        <thead className="table-background ">
          <tr className="sticky-header bg-white">
            <th>Vendor</th>
            <th>Purchase Count</th>
          </tr>
        </thead>
        
        {TopIteminfo.length > 0 ? (
          TopIteminfo.map((item, index) => (
            <tr key={index}>
              <td>{item.Company_Name}</td>
              <td>{item.purchaseCount}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="2">No data available</td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default TopItem;
