import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faMinus, faCutlery, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Calculation from './Calculation';
import ConvertTime from './convertTime';
import { useNavigate } from 'react-router-dom';

const Card = ({ handleFinished, handleCompleted, handleMinus, handleCancel, index, element, type, handleCookProcess }) => {
    const navigate = useNavigate();

    if (type === 'kitchen') {
        return (
            <div className="w-[300px]" key={index}>
                <div className='ticket'>
                    <div className='item-order-type'>
                        <div className='kot-id'>
                            <p>KOT ID: <span>{element.KOTID}</span></p>
                        </div>
                        <p className='item-type'>{element.orderType}</p>
                        <div className='top-icon'>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="check-icon"
                                onClick={() => handleCompleted(element.table_id)}
                            />
                        </div>
                    </div>
                    <div className='item-course-detail'>
                        <div className='item-detail'>
                            <div className='time-guests'>
                                <ConvertTime timeOrder={element.orderTime} />
                                <span>
                                    Guests: {element.Guest_count}
                                </span>
                            </div>
                            <div className='table-num'>Table No: {element.tableNum === "Take-Away" ? "" : element.tableNum}</div>
                            <span>{element.employee}</span>
                            <div className='time-order'>
                                <Calculation Ordertime={element.orderTime} />
                            </div>
                        </div>
                        {element.OrderItemDetailsList.map((item, index) => (
                            <div className='course-item' key={index}>
                                <table className="item-table">
                                    <tbody>
                                        <tr key={item.item_id} className='item-body-table'>
                                            <td className="quantity-box">{item.Quantity}</td>
                                            <td className="food">{item.ItemName}</td>
                                            <td >
                                                <div className="item-check-process">
                                                    <FontAwesomeIcon icon={faCheck} className="completed-icon" onClick={() => handleFinished(item.item_id)} />
                                                    {item.Quantity > 1 ? (
                                                        <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleMinus(item.item_id)} />
                                                    ) : (
                                                        <FontAwesomeIcon icon={faTimes} className="delete-icon" onClick={() => handleCancel(item.item_id)} />
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                        <div className='seen-div'>
                            {(element.currentState === "Started") ?
                                <button className='seen-btn' onClick={() => handleCookProcess(element.table_id)}>
                                    SEEN
                                </button>
                                :
                                <button className='start-cooking'>
                                    <FontAwesomeIcon icon={faCutlery} />
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (type) {
        return (
            <div className="w-[300px]" key={index}>
                <div className='ticket'>
                    <div className='item-order-type'>
                        <div className='kot-id'>
                            <p>KOT ID: <span>{element.KOTID}</span></p>
                        </div>
                        <p className='item-type'>{element.orderType}</p>
                        <div className='top-icon'>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="check-icon"
                            />
                        </div>
                    </div>
                    <div className='item-course-detail'>
                        <div className='item-detail'>
                            <div className='time-guests'>
                                <ConvertTime timeOrder={element.orderTime} />
                                <span>
                                    Guests: {element.Guest_count}
                                </span>
                            </div>
                            <div className='table-num'>Table No: {element.tableNum === "Take-Away" ? "" : element.tableNum}</div>
                            <span>{element.employee}</span>
                            <div className='time-order'>
                                <Calculation Ordertime={element.orderTime} />
                            </div>
                        </div>
                        {element.OrderItemDetailsList.map((item, index) => (
                            <div className='course-item' key={index}>
                                <table className="item-table">
                                    <tbody>
                                        <tr key={item.item_id} className='item-body-table'>
                                            <td className="quantity-box">{item.Quantity}</td>
                                            <td className="food">{item.ItemName}</td>
                                            <td>
                                                <div className="item-check-process">
                                                    <FontAwesomeIcon icon={faCheck} className="completed-icon" />
                                                    {item.Quantity > 1 ? (
                                                        <FontAwesomeIcon icon={faMinus} className="minus-icon" />
                                                    ) : (
                                                        <FontAwesomeIcon icon={faTimes} className="delete-icon" />
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    if (!type) {
        navigate('/')
    }
}

export default Card;
