import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PurchaseCountTable from "./PurchaseCountTable";
import PurchaseVolTable from "./PurchaseVolTable";
import TopVendor from "./TopVendor";
import TopItem from "./TopItem";
import { Bar, Line } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { formatSalesValue } from './../../utils/formatUtils';

const Invent = () => {
    const [outletOptions, setOutletOptions] = useState([]);
    const [selectedOutlet, setSelectedOutlet] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [inputValue, setInputValue] = useState("");
    const [dateToday, setDateToday] = useState(new Date().toISOString().split("T")[0]);
    const [purchaseRequisitions, setPurchaseRequisitions] = useState([]);
    const [purchaseItems, setPurchaseItems] = useState([]);
    const [purchaseDateRange, setPurchaseDateRange] = useState({ from: "", to: "" });
    const [topItem, setTopItem] = useState([]);
    const [topVendor, setTopVendor] = useState([]);
    const [purchaseCountTable, setPurchaseCountTable] = useState([]);
    const [purchaseVolTable, setPurchaseVolTable] = useState([]);
    const [newData, setNewData] = useState([]);
    const [purchaseData, setPurchaseData] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const [vendorChart, setVendorChart] = useState([]);
    const [itemdata, setItemData] = useState([]);
    const [ItemSelected, setItemSelected] = useState(null);
    const [token, setToken] = useState("");
    const [tableHeight, setTableHeight] = useState(null);
    const monthlyTableRef = useRef(null);

    let navigate = useNavigate()

    useEffect(() => {
        const fetchOutlets = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/outlets`,
                    { token: "test" }
                );
                const outlets = response.data.map((outlet) => outlet.name);
                setOutletOptions(outlets);
                if (outlets.length > 0) {
                    setSelectedOutlet(outlets[0]);
                }
            } catch (err) {
                console.error("Error fetching outlets:", err);
            }
        };

        fetchOutlets();
    }, []);

    useEffect(() => {
        if (selectedOutlet) {
            fetchData(selectedOutlet);
            fetchSVPData(selectedOutlet);
        }
    }, [selectedOutlet]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            console.log("Search by vendor:", inputValue);
        }
    };

    const handleDate = () => {
        console.log("Dates updated:", startDate, endDate);

        setPurchaseDateRange({
            from: startDate.toISOString().split("T")[0],
            to: endDate.toISOString().split("T")[0],
        });
    };

    const handleInfo = () => {
        console.log("Search Term:", inputValue);
    };

    const fetchData = async () => {
        const formattedStartDate = startDate.toISOString().split("T")[0];
        const formattedEndDate = endDate.toISOString().split("T")[0];
        const url = `${process.env.REACT_APP_BASE_URL}/reqfilterfirst/?secondtime=${formattedEndDate}&firsttime=${formattedStartDate}&outlet_name=${encodeURIComponent(selectedOutlet)}`;
        try {
            const response = await axios.get(url);
            setPurchaseItems(response.data.purchase_items_data || []);
            setPurchaseRequisitions(response.data.purchaserequisition || []);
            setVendorChart(response.data.vendorwise_totals);
        } catch (err) {
            console.error("Error fetching purchase requisitions:", err);
        }
    }

    const fetchSVPData = async (outlet) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/salesvspurchase`,
                {
                    token: "test",
                    outlet: outlet
                }
            );
            setTopItem(response.data.toppurchasingvendors_bycount);
            setTopVendor(response.data.toppurchasingvendors_byvolume);
            setPurchaseCountTable(response.data.purchaseitembycount_responseJson);
            setPurchaseVolTable(response.data.purchaseitembyvolume_responseJson);

            setNewData(response.data);
            setPurchaseData(response.data.monthlyPurchases);
            setSalesData(response.data.monthlySales);
        } catch (err) {
            console.error("Error fetching sale vs purchase data:", err);
        }
    };

    const handleSearch = () => {
        handleDate();
        handleInfo();
        fetchData();
        fetchSVPData(selectedOutlet);
    };

    const lineData = {
        labels: purchaseData && purchaseData.length > 0 ? purchaseData.map((item) => item.monthName) : [], // Check if data exists
        datasets: [
            {
                label: "Sales vs Time Chart",
                backgroundColor: "rgb(255, 99, 132)",
                borderColor: "#94a3b8",
                data: salesData && salesData.length > 0 ? salesData.map((item) => parseFloat(item.Total)) : [], // Check if data exists
            },
            {
                label: "Purchase vs Time Chart",
                backgroundColor: "rgb(54, 162, 235)",
                borderColor: "#4b6cb7",
                data: purchaseData && purchaseData.length > 0 ? purchaseData.map((item) => parseFloat(item.Total)) : [], // Check if data exists
            },
        ],
    };

    const barData = {
        labels: vendorChart && vendorChart.length > 0 ? vendorChart.map((item) => item.vendor) : [], // Check if data exists
        datasets: [
            {
                label: "Sales vs Vendor",
                backgroundColor: "rgb(255, 99, 132)",
                borderColor: "#94a3b8",
                data: vendorChart && vendorChart.length > 0 ? vendorChart.map((item) => parseFloat(item.total)) : [], // Check if data exists
            },
        ],
    };


    const handleChange = (element) => {
        const {
            IDIntbl_PurchaseRequisition,
            Date,
            Company_Name,
            Outlet_Name,
            State,
            purchaseBillNumber,
            DiscountAmount,
            TaxAmount,
            TotalAmount,
            GRN,
        } = element;
        localStorage.setItem("ID", IDIntbl_PurchaseRequisition);
        localStorage.setItem("date", Date);
        localStorage.setItem("company name", Company_Name);
        localStorage.setItem("outlet name", Outlet_Name);
        localStorage.setItem("state", State);
        localStorage.setItem("bill num", purchaseBillNumber);
        localStorage.setItem("discount", DiscountAmount);
        localStorage.setItem("tax", TaxAmount);
        localStorage.setItem("total", TotalAmount);
        localStorage.setItem("GRN", GRN);
        navigate("/detail");
    };

    const handleStatement = async () => {
        navigate("/statement");
    };
    const handleCostCenter = async () => {
        navigate("/costcenter");
    };
    const handleDetailedReport = async () => {
        navigate("/detailedpurchasereport");
    }

    const handleItem = async () => {
        // try {
        //     // Convert startDate and endDate to YYYY-MM-DD format (to match API requirements)
        //     const startFormatted = startDate.toISOString().slice(0, 10);
        //     const endFormatted = endDate.toISOString().slice(0, 10);

        //     // Make the API request with dynamic values
        //     const result = await axios.post(
        //         `${process.env.REACT_APP_BASE_URL}/purchasefiltervendorwisefrompurchaseitem`,
        //         {
        //             token: test, // Use the token state instead of hardcoded "test"
        //             item: ItemSelected, // Use ItemSelected directly
        //             dateStart: startFormatted, // Start date in YYYY-MM-DD format
        //             dateEnd: endFormatted, // End date in YYYY-MM-DD format
        //         }
        //     );

        //     // Ensure the response has valid data
        //     if (result && result.data && result.data.data) {
        //         const itemData = result.data.data; // Set the fetched data into itemdata

        //         // Navigate to '/items' page and pass the item data as state
        //         navigate("/items", { state: { itemdata: itemData } });
        //     } else {
        //         setItemData([]); // Handle the case where no data is returned
        //     }
        // } catch (error) {
        //     console.log(error); // Handle error
        //     setItemData([]); // Reset itemdata on error
        // }
        navigate('/items');
    };

    useEffect(() => {
        if (monthlyTableRef.current) {
            setTableHeight(monthlyTableRef.current.clientHeight);
        }
    }, [purchaseData, salesData]);

    return (
        <>
            <Navbar />
            <div className="flex flex-col items-center mt-6">
                {/* Selected Outlet Display */}
                <h2 className="text-xl font-semibold mb-4">
                    {selectedOutlet ? `${selectedOutlet}` : "Loading..."}
                </h2>

                <div className="flex justify-between w-full px-4"> {/* Added padding to the sides */}
                    {/* Outlet Select */}
                    <div className="text-center">
                        <select
                            id="outlet"
                            value={selectedOutlet}
                            onChange={(e) => setSelectedOutlet(e.target.value)}
                            className="border px-4 py-2 rounded cursor-pointer w-72"
                        >
                            {outletOptions.map((outlet, index) => (
                                <option key={index} value={outlet}>
                                    {outlet}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Buttons Section */}
                    <div className="flex flex-col space-y-2">
                        <button
                            className="w-24 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg md:h-9"
                            onClick={handleDetailedReport}
                        >
                            Detailed Purchase Report
                        </button>
                        <button
                            className="w-24 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg md:h-9"
                            onClick={handleStatement}
                        >
                            View Statement
                        </button>
                        <button
                            className="w-24 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg md:h-9"
                            onClick={handleCostCenter}
                        >
                            Cost Center
                        </button>
                        <button
                            className="w-24 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg md:h-9"
                            onClick={handleItem}
                        >
                            Item History
                        </button>
                    </div>
                </div>


                {/* Date Selection, Search, and Vendor */}
                <div className="hidden md:flex border-2 border-slate-500 w-full justify-between my-4 mx-2 relative z-50">
                    <div className="flex items-center md:flex-row">
                        <div className="w-full flex flex-between flex-col m-2">
                            <label className="font-medium font-poppins md:text-base text-center">
                                Date Selection
                            </label>
                            <div className="flex w-full">
                                <div className="relative">
                                    <label className="font-base font-poppins md:text-base">From:</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        className="w-16 md:w-24 text-xs md:text-base cursor-pointer"
                                        popperPlacement="bottom"  // Ensure the calendar appears below
                                        popperModifiers={[
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, 8],  // Adjusting offset to make sure the calendar appears correctly
                                                },
                                            },
                                            {
                                                name: 'zIndex',
                                                options: {
                                                    zIndex: 9999,  // Ensures the calendar appears above other elements
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="relative">
                                    <label className="font-base font-poppins md:text-base">To:</label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        className="w-16 md:w-24 text-xs md:text-base cursor-pointer"
                                        popperPlacement="bottom"  // Ensure the calendar appears below
                                        popperModifiers={[
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, 8],  // Adjusting offset to make sure the calendar appears correctly
                                                },
                                            },
                                            {
                                                name: 'zIndex',
                                                options: {
                                                    zIndex: 9999,  // Ensures the calendar appears above other elements
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="my-4">
                                <button
                                    onClick={handleSearch}
                                    className="w-12 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg md:h-7 md:w-16 md:text-xs">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center flex-col justify-center">
                        <h4 className="font-medium font-poppins md:text-base text-center mt-4">
                            Purchase Entry:
                        </h4>
                        <div className="flex">
                            <p className="px-2 mt-2">
                                From: <span>{purchaseDateRange.from || dateToday}</span>
                            </p>
                            <p className="px-2">
                                To: <span>{purchaseDateRange.to || dateToday}</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="md:my-2">
                            <label className="px-2 font-medium font-poppins md:text-base text-center">Search:</label>
                            <input
                                type="text"
                                className="border-2 border-slate-400 rounded-lg h-5 md:h-8 text-xs md:text-base placeholder:text-center placeholder:italic"
                                value={inputValue}
                                onChange={handleInputChange}
                                placeholder="Vendor"
                                onKeyDown={handleKeyPress}
                            />
                        </div>
                        <div className="px-2">
                            <button
                                onClick={fetchData}
                                className="w-20 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg  md:h-9">
                                View Reports
                            </button>
                        </div>
                    </div>
                </div>

                <div className="w-[95%] mx-auto relative">
                    {/* Purchase Requisition Table */}
                    <div
                        style={{
                            width: "100%",
                            overflowX: "auto",
                            marginTop: "20px",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                            maxHeight: "400px", // Set a max height for scrollable area
                            overflowY: "auto", // Enable vertical scrolling
                            position: "relative",
                        }}
                    >
                        <table
                            style={{
                                width: "100%",
                                minWidth: "1200px",
                                borderCollapse: "collapse",
                                textAlign: "center",
                            }}
                            className="2xl:text-base xl:text-sm"
                        >
                            <thead
                                style={{
                                    backgroundColor: "#f8f8f8",
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 10,
                                }}
                            >
                                <tr>
                                    {[
                                        "S.N", "Vendor", "Ordered Date", "Received Date", "Tax", "Total", "State", "GRN", "Purchase Order ID", "View"
                                    ].map((header, index) => (
                                        <th
                                            key={index}
                                            style={{
                                                padding: "10px",
                                                whiteSpace: "nowrap",
                                                backgroundColor: "#f8f8f8",
                                            }}
                                        >
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseRequisitions.length === 0 ? (
                                    <tr>
                                        <td colSpan="10" style={{ padding: "10px" }}>No data available!</td>
                                    </tr>
                                ) : (
                                    purchaseRequisitions.map((item, index) => (
                                        <tr key={item.IDIntbl_PurchaseRequisition} style={{ borderBottom: "1px solid #ddd" }}>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{index + 1}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{item.Company_Name || 'N/A'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{new Date(item.Date).toLocaleDateString('en-GB')}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{new Date(item.ReceivedDate).toLocaleDateString('en-GB')}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{item.TaxAmount || '0.00'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{formatSalesValue(item.TotalAmount) || '0.00'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{item.State || 'N/A'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{item.GRN || 'N/A'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{item.IDIntbl_PurchaseRequisition}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>
                                                <button
                                                    style={{
                                                        border: "2px solid #16a34a", // Tailwind green-600
                                                        borderRadius: "8px",
                                                        width: "64px", // Equivalent to Tailwind w-16
                                                        height: "32px", // Equivalent to Tailwind h-8
                                                        backgroundColor: "transparent",
                                                        color: "#16a34a",
                                                        cursor: "pointer",
                                                        transition: "background-color 0.3s, color 0.3s",
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        e.target.style.backgroundColor = "#16a34a"; // Hover background color
                                                        e.target.style.color = "#e5e7eb"; // Tailwind gray-200
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.target.style.backgroundColor = "transparent"; // Reset background
                                                        e.target.style.color = "#16a34a"; // Reset text color
                                                    }}
                                                    onClick={() => handleChange(item)}
                                                >
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                            <tfoot
                                style={{
                                    position: "sticky",
                                    bottom: 0,
                                    backgroundColor: "#f8f8f8",
                                    fontWeight: "bold",
                                    zIndex: 10,
                                }}
                            >
                                <tr>
                                    <td colSpan="4" style={{ padding: "10px", textAlign: "left" }}>Count: {purchaseRequisitions.length}</td>
                                    <td style={{ padding: "10px", whiteSpace: "nowrap" }}>
                                        Total Tax: {purchaseRequisitions.reduce((acc, item) => acc + parseFloat(item.TaxAmount || 0), 0).toFixed(2)}
                                    </td>
                                    <td style={{ padding: "10px", whiteSpace: "nowrap" }}>
                                        Total: {purchaseRequisitions.reduce((acc, item) => acc + parseFloat(item.TotalAmount || 0), 0).toFixed(2)}
                                    </td>
                                    <td colSpan="4"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>


                    <br />

                    {/* Purchase Items Table */}
                    <div
                        style={{
                            width: "100%",
                            overflowX: "auto",
                            marginTop: "20px",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                            maxHeight: "400px", // Set a max height for scrollable area
                            overflowY: "auto", // Enable vertical scrolling
                        }}
                    >
                        <table
                            style={{
                                width: "100%",
                                minWidth: "1200px",
                                borderCollapse: "collapse",
                                textAlign: "center",
                            }}
                            className="2xl:text-base xl:text-sm"
                        >
                            <thead
                                style={{
                                    backgroundColor: "#f8f8f8",
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 10,
                                }}
                            >
                                <tr>
                                    {[
                                        "S.N", "Name", "Group Name", "Vendor Name", "Unit", "UOM", "Rate", "Total", "Tax"
                                    ].map((header, index) => (
                                        <th
                                            key={index}
                                            style={{
                                                padding: "10px",
                                                whiteSpace: "nowrap",
                                                backgroundColor: "#f8f8f8",
                                            }}
                                        >
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseItems.length === 0 ? (
                                    <tr>
                                        <td colSpan="9" style={{ padding: "10px" }}>No data available!</td>
                                    </tr>
                                ) : (
                                    purchaseItems.map((item, index) => (
                                        <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{index + 1}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{item.Name || 'N/A'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{item.GroupName || 'N/A'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{item.Company_Name || 'N/A'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{item.Count || 'N/A'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{item.UOM || 'N/A'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{formatSalesValue(item.Rate) || '0.00'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{formatSalesValue(item.Total) || '0.00'}</td>
                                            <td style={{ padding: "10px", whiteSpace: "nowrap" }}>{item.Taxable || '0.00'}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="flex justify-evenly mb-5 mt-16">
                    <div className="flex flex-col">
                        <p className="flex justify-center font-serif">Top Item by Volume</p>
                        <PurchaseVolTable PurchaseVolTableinfo={purchaseVolTable} />
                    </div>
                    <div className="flex flex-col">
                        <p className="flex justify-center font-serif">Top Item by Count</p>
                        <PurchaseCountTable PurchaseCountTableinfo={purchaseCountTable} />
                    </div>
                    <div className="flex flex-col">
                        <p className="flex justify-center font-serif">Top Vendor by Volume</p>
                        <TopVendor TopVendorinfo={topVendor} />
                    </div>
                    <div className="flex flex-col">
                        <p className="flex justify-center font-serif">Top Vendor by Count</p>
                        <TopItem TopIteminfo={topItem} />
                    </div>
                </div>

                <div className="flex flex-wrap md:flex-nowrap justify-center items-center gap-12 mt-8">
                    {/* Bar Chart */}
                    <div className="w-[650px] h-[500px] p-6 bg-white shadow-xl rounded-2xl">
                        <h2 className="text-center font-semibold 2xl:text-xl xl:text-lg text-gray-800 mb-4">
                            Sales by Vendor
                        </h2>
                        <div className="h-[400px]">
                            <Bar data={barData} options={{ maintainAspectRatio: false }} />
                        </div>
                    </div>

                    {/* Line Chart */}
                    <div className="w-[650px] h-[500px] p-6 bg-white shadow-xl rounded-2xl">
                        <h2 className="text-center font-semibold 2xl:text-xl xl:text-lg text-gray-800 mb-4">
                            Sales vs Purchase Over Time
                        </h2>
                        <div className="h-[400px]">
                            <Line data={lineData} options={{ maintainAspectRatio: false }} />
                        </div>
                    </div>
                </div>

                <div className="flex justify-center w-[90%] mx-auto mb-10 gap-10 mt-20">
                    {/* Vendor Sales Table (Scrolls if Data Exceeds Monthly Table Height) */}
                    <div className="w-1/2">
                        <div
                            className="border border-gray-300 rounded-2xl overflow-y-auto"
                            style={{ maxHeight: tableHeight ? `${tableHeight}px` : "auto" }}
                        >
                            <table className="w-full text-center border-collapse 2xl:text-base xl:text-sm">
                                <thead className="bg-slate-50 sticky top-0">
                                    <tr className="border-b-2 border-gray-400">
                                        <th className="p-2">Vendor</th>
                                        <th className="p-2">Sales</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vendorChart && vendorChart.length > 0 ? (
                                        vendorChart.map((item, index) => (
                                            <tr key={index} className="border-b border-gray-200">
                                                <td className="p-2">{item.vendor}</td>
                                                <td className="p-2">{formatSalesValue(item.total)}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="2" className="p-4">Data not available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Monthly Purchase & Sales Tables (Auto Height, Non-Scrollable) */}
                    <div className="w-1/2 flex" ref={monthlyTableRef}>
                        {/* Monthly Purchase Table */}
                        <div className="w-1/2 px-2">
                            <div className="border border-gray-300 rounded-2xl">
                                <table className="w-full text-center border-collapse 2xl:text-base xl:text-sm">
                                    <thead className="bg-slate-50">
                                        <tr className="border-b-2 border-gray-400">
                                            <th className="p-2">Month</th>
                                            <th className="p-2">Purchase</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {purchaseData.length > 0 ? (
                                            purchaseData.map((item, index) => (
                                                <tr key={index} className="border-b border-gray-200">
                                                    <td className="p-2">{item.monthName}</td>
                                                    <td className="p-2">{formatSalesValue(item.Total)}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="2" className="p-4">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Monthly Sales Table */}
                        <div className="w-1/2 px-2">
                            <div className="border border-gray-300 rounded-2xl">
                                <table className="w-full text-center border-collapse 2xl:text-base xl:text-sm">
                                    <thead className="bg-slate-50">
                                        <tr className="border-b-2 border-gray-400">
                                            <th className="p-2">Month</th>
                                            <th className="p-2">Sales</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salesData.length > 0 ? (
                                            salesData.map((item, index) => (
                                                <tr key={index} className="border-b border-gray-200">
                                                    <td className="p-2">{item.monthName}</td>
                                                    <td className="p-2">{formatSalesValue(item.Total)}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="2" className="p-4">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Footer />
        </>
    );
};

export default Invent;
