import React, { useEffect, useRef, useState } from 'react';
import DateFormat from './DateFormat';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';

const ComplimentaryTable = ({ complimentary, complimentaryTotal, start, end }) => {
    let navigate = useNavigate();
    const tableRef = useRef(null);

    const [token, setToken] = useState("");
    const [modalVisible, setModalVisible] = useState(false); // For modal visibility
    const [modalData, setModalData] = useState(null); // For storing modal data
    const [status, setStatus] = useState(false);

    useEffect(() => {
        let tokenCheck = localStorage.getItem("token");
        if (!tokenCheck) {
            navigate('/')
        } else {
            setToken(localStorage.getItem("token"))
        }
    }, [])

    const handleExport = () => {
        setStatus(true);
    };

    // Handle Bill ID click to fetch data
    const handleBillID = (idtblorderHistory) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/billinfo_byid`, {
            token: token,
            bill_id: idtblorderHistory
        })
            .then((response) => {
                if (response?.data) {
                    setModalData(response.data); // Set the received data
                    setModalVisible(true); // Show the modal
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Close the modal
    const closeModal = () => {
        setModalVisible(false);
        setModalData(null);
    }

    return (
        <div className="table-customer-history">
            <DownloadTableExcel
                filename="customer_portal"
                sheet="Complimentary"
                currentTableRef={tableRef.current}>
                <button className="export" onClick={handleExport}>
                    Export
                </button>
            </DownloadTableExcel>
            <div  className='customer-table-title'>
                <h5>Complimentary List</h5>
            </div>
            <div className="table-history-responsive table-data-complimentary">
                <table className="table-complementary" ref={tableRef}>
                    <thead>
                        <tr className='position-sticky'>
                            <th>Date</th>
                            <th>Order ID</th>
                            <th>Customer</th>
                            <th>Total</th>
                            <th>Mode</th>
                        </tr>
                    </thead>
                    {complimentary.map((item) => (
                        <tr key={item.idtblorderHistory}>
                            <td><DateFormat date={item.Date} /></td>
                            <td>
                                <button onClick={() => handleBillID(item.idtblorderHistory)}>
                                    {item.idtblorderHistory}
                                </button>
                            </td>
                            <td>{item.Customer}</td>
                            <td>{item.Total}</td>
                            <td>{item.Mode}</td>
                        </tr>
                    ))}
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>Total Complimentary:</td>
                            <td>{complimentaryTotal}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            {/* Modal for displaying the bill details */}
            {modalVisible && modalData && (
                <div className="custom-modal-overlay" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="custom-modal-dialog">
                        <div className="custom-modal-content">
                            {/* Close button (cross) */}
                            <button type="button" className="custom-close-btn" onClick={closeModal} aria-label="Close">
                                &times;
                            </button>

                            <div className="custom-modal-header">
                                <h5 className="custom-modal-title" id="exampleModalLabel">Bill Details</h5>
                                <button type="button" className="custom-close-btn" onClick={closeModal} aria-label="Close"></button>
                            </div>
                            <div className="custom-modal-body">
                                {/* Order Summary (Top part) */}
                                <div className="custom-order-summary">
                                    <div className="custom-col">
                                        <ul className="custom-details-list">
                                            <li><strong>Date:</strong> {modalData.Date}</li>
                                            <li><strong>Start Time:</strong> {modalData.Start_Time}</li>
                                            <li><strong>End Time:</strong> {modalData.End_Time}</li>
                                            <li><strong>Payment Mode:</strong> {modalData.PaymentMode}</li>
                                            <li><strong>Table No:</strong> {modalData.Table_No}</li>
                                            <li><strong>Total Count:</strong> {modalData.TotalCount}</li>
                                            <li><strong>Type:</strong> {modalData.Type}</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Item Details (Table) */}
                                <table className="custom-item-table">
                                    <thead>
                                        <tr>
                                            <th>Item Type</th>
                                            <th>Item Name</th>
                                            <th>Quantity</th>
                                            <th>Rate</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modalData.details.map((detail, index) => (
                                            <tr key={index}>
                                                <td>{detail.ItemType}</td>
                                                <td>{detail.itemName}</td>
                                                <td>{detail.Quantity}</td>
                                                <td>{detail.itemrate}</td>
                                                <td>{detail.total}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {/* Employee, Service Charge, VAT below the item details table */}
                                <div className="custom-charges">
                                    <ul className="custom-charges-list">
                                        <li><strong>Employee:</strong> {modalData.employee}</li>
                                        <li><strong>Service Charge:</strong> {modalData.serviceCharge}</li>
                                        <li><strong>VAT:</strong> {modalData.vat}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="custom-modal-footer">
                                <div className="custom-footer-content">
                                    <strong>Total:</strong>
                                    <strong>{modalData.total}</strong>
                                </div>
                                <button type="button" className="custom-footer-close-btn" onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </div>
    )
}

export default ComplimentaryTable;
