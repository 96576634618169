import React, { useState } from 'react';
import { formatSalesValue } from './../../utils/formatUtils';

const Modal = ({ open, onClose, children }) => {
    if (!open) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-4 rounded shadow-lg w-96 relative">
                {children}
                <button onClick={onClose} className="mt-2 bg-red-500 text-white px-4 py-2 rounded absolute top-2 right-2">Close</button>
            </div>
        </div>
    );
};

const AgeingDebtors = ({ debtData }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    if (!debtData || Object.keys(debtData).length === 0) {
        return <div>Loading...</div>;
    }

    const ageingCategories = ["0-30", "31-60", "61-90", "91-180", "181-365", "366+"];

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setModalOpen(true);
    };

    return (
        <div className='table-credit-left-responsive'>
            <table className='table-credit-left'>
                <thead>
                    <tr className='position-sticky'>
                        <th>Debtor Type</th>
                        {ageingCategories.map((category) => (
                            <th key={category}>
                                <button onClick={() => handleCategoryClick(category)}>{category}</button>
                            </th>
                        ))}
                        <th>Net Due</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='font-semibold'>Individual</td>
                        {ageingCategories.map((category) => (
                            <td key={category}>
                                {formatSalesValue(debtData[category]?.totalInitial || 0)}
                            </td>
                        ))}
                        <td>{formatSalesValue(ageingCategories.reduce((sum, category) => sum + (debtData[category]?.totalInitial || 0), 0))}</td>
                    </tr>
                    <tr>
                        <td className='font-semibold'>Payments</td>
                        {ageingCategories.map((category) => (
                            <td key={category}>
                                {formatSalesValue(debtData[category]?.totalPayment || 0)}
                            </td>
                        ))}
                        <td>{formatSalesValue(ageingCategories.reduce((sum, category) => sum + (debtData[category]?.totalPayment || 0), 0))}</td>
                    </tr>
                    <tr>
                        <td className='font-semibold'>Remaining</td>
                        {ageingCategories.map((category) => (
                            <td key={category}>
                                {formatSalesValue(debtData[category]?.remainingTotal || 0)}
                            </td>
                        ))}
                        <td>{formatSalesValue(ageingCategories.reduce((sum, category) => sum + (debtData[category]?.remainingTotal || 0), 0))}</td>
                    </tr>
                </tbody>
            </table>

            {modalOpen && selectedCategory && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-10 rounded shadow-lg relative">
                        <h2 className='text-lg font-bold text-center'>Details for {selectedCategory} Days</h2>
                        <table className='table-credit-left'>
                            <thead>
                                <tr className='position-sticky'>
                                    <th>Customer Name</th>
                                    <th>Guest ID</th>
                                    <th>Initial Total</th>
                                    <th>Payment Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {debtData[selectedCategory]?.data?.length > 0 ? (
                                    debtData[selectedCategory].data.map((item) => (
                                        <tr key={item.guestID}>
                                            <td>{item.customerName}</td>
                                            <td>{item.guestID}</td>
                                            <td>{formatSalesValue(item.InitialTotal)}</td>
                                            <td>{formatSalesValue(item.PaymentTotal)}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan='4'>No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className='flex justify-end'>
                            <button onClick={() => setModalOpen(false)} className="mt-2 bg-red-500 text-white px-4 py-2 rounded">Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AgeingDebtors;