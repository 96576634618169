import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import SalesInDetail from './SalesInDetail';
import SelectSearchInput from '../SelectSearch';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import DateRangeChart from './DateRangeChart';

const ForecastDetails = () => {
    const { state } = useLocation();
    const { start: initialStart, end: initialEnd } = state || {};

    const [token, setToken] = useState('');
    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [startDate, setStartDate] = useState(initialStart || '');
    const [endDate, setEndDate] = useState(initialEnd || '');
    const [forecastData, setForecastData] = useState(null);
    const [dailyData, setDailyData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fetchOnLoad, setFetchOnLoad] = useState(true);
    const [exceeded, setExceeded] = useState(false);

    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0];

    // Fetch token and default outlet from localStorage
    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const storedOutlet = localStorage.getItem('outlet');

        if (!storedToken) {
            window.location.href = '/'; // Redirect if no token
        } else {
            setToken(storedToken);
            if (storedOutlet) {
                setSelectedOutlet(storedOutlet);
            }
        }
    }, []);

    // Fetch forecast and daily data on initial load if the outlet, start date, and end date are available
    useEffect(() => {
        if (selectedOutlet && startDate && endDate && fetchOnLoad) {
            fetchData();
            setFetchOnLoad(false);
        }
    }, [selectedOutlet, startDate, endDate, fetchOnLoad]);

    const fetchData = async () => {
        if (!selectedOutlet || !startDate || !endDate) return;

        setLoading(true);
        try {
            // Fetch forecast data
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/getsaleforecastbydaterange`,
                {
                    outlet_name: selectedOutlet,
                    dateStart: startDate,
                    dateEnd: endDate,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            let fetchedForecastData = response.data.data;

            // Check if today's date is within the selected range
            if (today >= startDate && today <= endDate) {
                const dailyResponse = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/dailyreport`,
                    {
                        outlet: selectedOutlet,
                        dateStart: today,
                        dateEnd: today,
                        token: token,
                    }
                );

                const fetchedDailyData = dailyResponse.data.daily_data;
                setDailyData(fetchedDailyData);

                // Update today's actual sales in forecastData
                fetchedForecastData = fetchedForecastData.map((forecastItem) => {
                    const dailyItem = fetchedDailyData.find(
                        (item) => item.Date === forecastItem.ds
                    );

                    if (dailyItem) {
                        forecastItem.actual_sales = dailyItem.TOTALSALES || 0;
                    }

                    return forecastItem;
                });
            }

            setForecastData(fetchedForecastData);
        } catch (error) {
            console.error('Error fetching forecast or daily data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        if (!selectedOutlet) {
            return;
        }
        fetchData();
        setFetchOnLoad(false);
    };

    // Check if the length of forecastData exceeds 31
    const isLongData = forecastData && forecastData.length > 31;

    useEffect(() => {
        if (forecastData && forecastData.length > 31) {
            setExceeded(true);
        } else {
            setExceeded(false); // Reset to false if data length is <= 31
        }
    }, [forecastData]);

    return (
        <div className='flex flex-col min-h-screen'>
            <Navbar />
            <div className={`flex-grow flex ${isLongData ? 'flex-col-reverse' : ''}`}>
                <div
                    className={`${isLongData ? 'justify-center' : 'w-1/5 flex-col'} flex p-6`}
                >
                    {forecastData && Array.isArray(forecastData) && <SalesInDetail forecastData={forecastData} exceeded={exceeded} />}
                </div>

                <div
                    className={`${isLongData ? 'w-full' : 'w-4/5'} bg-white shadow-lg rounded-lg transition-all duration-300 ease-in-out`}
                >
                    <div className="flex justify-around text-center mb-2 mt-6">
                        <div className="flex flex-col items-start">
                            <label htmlFor="startDate" className="text-md font-semibold">Start Date: </label>
                            <input
                                type="date"
                                id="startDate"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                style={{ margin: '0' }}
                            />
                        </div>
                        <SelectSearchInput
                            token={token}
                            setToken={setToken}
                            selectedOutlet={selectedOutlet}
                            setSelectedOutlet={setSelectedOutlet}
                        />
                        <div className="flex flex-col items-start">
                            <label htmlFor="endDate" className="text-md font-semibold">End Date: </label>
                            <input
                                type="date"
                                id="endDate"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                style={{ margin: '0' }}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <button
                            onClick={handleSearch}
                            className="border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg flex items-center justify-center px-4 py-2"
                            disabled={loading}
                        >
                            {loading ? 'Loading...' : 'Search'}
                        </button>
                    </div>

                    <h3 className="text-xl font-semibold text-center my-4">Sales Forecast</h3>
                    <div className='flex justify-center mb-5'>
                        {loading ? (
                            <div>Loading Chart...</div>
                        ) : (
                            <DateRangeChart forecastData={forecastData} today={today} islong={isLongData} />
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ForecastDetails;
