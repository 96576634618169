import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../scss/table.scss";
import "../../scss/info.scss";
import Footer from "../Footer";
import NavbarHome from "../Navbar/NavbarHome";

const VoidToday = () => {
    const [orders, setOrders] = useState([]);
    const [items, setItems] = useState([]);
    const [token, setToken] = useState("");
    const [type, setType] = useState();

    const navigate = useNavigate()
    const location = useLocation();
    const outlet = location.state?.outlet;

    useEffect(() => {
        let tokenCheck = localStorage.getItem("token");
        let typeCheck = localStorage.getItem("type")

        if (tokenCheck === null && !typeCheck) {
            navigate("/");
        } else {
            setToken(localStorage.getItem("token"));
            setType(typeCheck)
        }
    }, [navigate]);

    useEffect(() => {
        if (!token) return
        const today = new Date().toISOString().split('T')[0];
        const fetchVoidOrders = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/void-orders-today`, {
                    startDate: today,
                    endDate: today,
                    outletName: outlet
                }, { headers: { Authorization: `Bearer ${token}` } });

                const data = response.data;

                if (data.orders) {
                    setOrders(data.orders);
                    const extractedItems = data.orders.flatMap(order => order.items || []);
                    setItems(extractedItems);
                }
            } catch (error) {
                console.error("Error fetching void orders:", error);
            }
        };

        fetchVoidOrders();
    }, [outlet, token]);

    return (
        <div className="flex flex-col min-h-screen">
            <NavbarHome />
            <div className="information voids-today-info flex-grow">
                {orders.length === 0 ? (  // Check if there are no orders
                    <div className="text-center text-red-500 mt-10 font-bold text-xl">
                        No data available!!!
                    </div>
                ) : (
                    orders.map((order, index) => (
                        <div key={index} className="information">
                            <div className="show-table-info">
                                <div className='show-table-num'>
                                    <p>{(order.TableNum === "Take-Away") ? "" : "Table : "}<span>{order.TableNum}</span></p>
                                </div>
                                <div className='show-table'>
                                    <div className='show-table-emp'>
                                        <p>Employee :  <span>{order.Employee}</span></p>
                                    </div>
                                </div>
                                <div className='show-table-kot'>
                                    KOT ID : <span>{order.KOTID}</span>
                                </div>
                            </div>
                            <div className='show-info'>
                                <div className='show-info-num'>
                                    <p>{(order.TableNum === "Take-Away") ? "" : "Table :"}<span>{order.TableNum}</span></p>
                                </div>
                                <div className='show-info-kot'>
                                    KOT ID :<span>{order.KOTID}</span>
                                </div>
                                <div className='show-table'>
                                    <div className='show-info-emp'>
                                        <p>Employee :  <span>{order.Employee}</span></p>
                                    </div>
                                </div>
                            </div>

                            <div className="table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Quantity</th>
                                            <th>Item Name</th>
                                            <th>Ordered At</th>
                                            <th>Void At</th>
                                            <th>Void Time</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order.items.map((item, itemIndex) => (
                                            <tr key={itemIndex}>
                                                <td>{itemIndex + 1}</td>
                                                <td>{item.Quantity}</td>
                                                <td>{item.ItemName}</td>
                                                <td>{item.orderedAt}</td>
                                                <td>{item.voidAt}</td>
                                                <td>{item.voidTotalTime}</td>
                                                <td><div className="void">Void</div></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <Footer />
        </div>
    );
};

export default VoidToday;
