import React from "react";
import { formatSalesValue } from "../../utils/formatUtils";

const TodaysForecastTable = ({ updatedForecastData, left, right }) => {
    const today = new Date().toISOString().split("T")[0];
    const todaysData = updatedForecastData.filter(item => item.ds === today);

    if (left) {
        return (
            <table className="w-[400px] table-auto border-collapse 2xl:text-base xl:text-sm">
                <tbody>
                    {todaysData.length > 0 ? (
                        todaysData.map((item, index) => {
                            const actualSales = item.actual_sales || 0;
                            const forecast = item.yhat || 0;
                            const difference = actualSales - forecast;

                            return (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td className="border-b p-2 font-semibold">Actual Sales</td>
                                        <td className="border-b p-2 text-right">{formatSalesValue(actualSales)}</td>
                                    </tr>
                                    <tr>
                                        <td className="border-b p-2 font-semibold">Forecast</td>
                                        <td className="border-b p-2 text-right">{formatSalesValue(forecast)}</td>
                                    </tr>
                                    <tr>
                                        <td className="border-b p-2 font-semibold">Difference</td>
                                        <td className="border-b p-2 text-right">{formatSalesValue(difference)}</td>
                                    </tr>
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="2" className="border-b p-2 text-center">No Data Available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    if (right) {
        return (
            <table className="w-[400px] table-auto border-collapse 2xl:text-base xl:text-sm">
                <tbody>
                    {todaysData.length > 0 ? (
                        todaysData.map((item, index) => {
                            const lowerEstimate = item.yhat_lower || 0;
                            const upperEstimate = item.yhat_upper || 0;
                            const actualSales = item.actual_sales || 0;
                            const forecast = item.yhat || 0;
                            const difference = actualSales - forecast;
                            const differencePercentage = forecast !== 0 ? ((difference / forecast) * 100).toFixed(2) : "0.00";

                            return (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td className="border-b p-2 font-semibold">Lower Estimate</td>
                                        <td className="border-b p-2 text-right">{formatSalesValue(lowerEstimate)}</td>
                                    </tr>
                                    <tr>
                                        <td className="border-b p-2 font-semibold">Upper Estimate</td>
                                        <td className="border-b p-2 text-right">{formatSalesValue(upperEstimate)}</td>
                                    </tr>
                                    <tr>
                                        <td className="border-b p-2 font-semibold">Difference %</td>
                                        <td className="border-b p-2 text-right">{differencePercentage}%</td>
                                    </tr>
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="2" className="border-b p-2 text-center">No Data Available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    return (
        <div>
            <h2 className="text-lg font-semibold mb-2 mr-20">Today's Forecast Details:</h2>
            <div className="overflow-x-auto mb-6">
                <table className="min-w-full table-auto border-collapse 2xl:text-base xl:text-sm">
                    {/* <thead>
                        <tr className="bg-gray-100 text-center">
                            <th className="border border-gray-300 px-4 py-2">Actual Sales</th>
                            <th className="border border-gray-300 px-4 py-2">Forecast</th>
                            <th className="border border-gray-300 px-4 py-2">Lower Estimate</th>
                            <th className="border border-gray-300 px-4 py-2">Upper Estimate</th>
                            <th className="border border-gray-300 px-4 py-2">Difference</th>
                            <th className="border border-gray-300 px-4 py-2">Difference %</th>
                        </tr>
                    </thead> */}
                    <tbody>
                        {todaysData.length > 0 ? (
                            todaysData.map((item, index) => {
                                const actualSales = item.actual_sales || 0;
                                const forecast = item.yhat || 0;
                                const lowerEstimate = item.yhat_lower || 0;
                                const upperEstimate = item.yhat_upper || 0;
                                const difference = actualSales - forecast;
                                const differencePercentage = forecast !== 0 ? ((difference / forecast) * 100).toFixed(2) : "0.00";

                                return (
                                    // <tr key={index} className="hover:bg-gray-50 text-center">
                                    //     <td className="border border-gray-300 px-4 py-2">{formatSalesValue(actualSales)}</td>
                                    //     <td className="border border-gray-300 px-4 py-2">{formatSalesValue(forecast)}</td>
                                    //     <td className="border border-gray-300 px-4 py-2">{formatSalesValue(lowerEstimate)}</td>
                                    //     <td className="border border-gray-300 px-4 py-2">{formatSalesValue(upperEstimate)}</td>
                                    //     <td className="border border-gray-300 px-4 py-2">{formatSalesValue(difference)}</td>
                                    //     <td className="border border-gray-300 px-4 py-2">{differencePercentage}%</td>
                                    // </tr>
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td className="border-b p-2 font-semibold">Actual Sales</td>
                                            <td className="border-b p-2">{formatSalesValue(actualSales)}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-b p-2 font-semibold">Forecast</td>
                                            <td className="border-b p-2">{formatSalesValue(forecast)}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-b p-2 font-semibold">Difference</td>
                                            <td className="border-b p-2">{formatSalesValue(difference)}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-b p-2 font-semibold">Lower Estimate</td>
                                            <td className="border-b p-2">{formatSalesValue(lowerEstimate)}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-b p-2 font-semibold">Upper Estimate</td>
                                            <td className="border-b p-2">{formatSalesValue(upperEstimate)}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-b p-2 font-semibold">Difference %</td>
                                            <td className="border-b p-2">{differencePercentage}%</td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="6" className="py-2 text-base">No Data Available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TodaysForecastTable;
