import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import Navbar from "../Navbar";
import Footer from "../Footer";

const FileUpload = () => {
    const [sheets, setSheets] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState("");
    const [data, setData] = useState([]);
    const [fileName, setFileName] = useState("");
    const [outlets, setOutlets] = useState([]);
    const [selectedOutlet, setSelectedOutlet] = useState("");
    const [category, setCategory] = useState("");

    // Fetch outlets on component mount
    useEffect(() => {
        const fetchOutlets = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/outlets`, { token: "test" });
                setOutlets(response.data);

                // Set the first outlet as default if outlets are available
                if (response.data.length > 0) {
                    setSelectedOutlet(response.data[0].id); // Set the first outlet as default
                }
            } catch (error) {
                console.error("Error fetching outlets:", error);
            }
        };

        fetchOutlets();
    }, []);

    // Handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setFileName(file.name);

        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = (e) => {
            const binaryString = e.target.result;
            const workbook = XLSX.read(binaryString, { type: "binary" });

            setSheets(workbook.SheetNames);
            setSelectedSheet(workbook.SheetNames[0]); // Default to first sheet
            loadSheetData(workbook, workbook.SheetNames[0]);
        };
    };

    // Load data from the selected sheet
    const loadSheetData = (workbook, sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        setData(parsedData); // Store all rows, including the header
    };

    // Handle sheet change
    const handleSheetChange = (event) => {
        const newSheet = event.target.value;
        setSelectedSheet(newSheet);

        const reader = new FileReader();
        reader.readAsBinaryString(document.querySelector("input[type=file]").files[0]);

        reader.onload = (e) => {
            const binaryString = e.target.result;
            const workbook = XLSX.read(binaryString, { type: "binary" });
            loadSheetData(workbook, newSheet);
        };
    };

    // Handle upload
    const handleUpload = () => {
        if (!selectedOutlet) {
            alert("Please select an outlet.");
            return;
        }

        if (!category) {
            alert("Please select a category.");
            return;
        }

        // Prepare the data in the expected format (excluding the first row, which is the header)
        const formattedData = {
            menu: data.slice(1).map((row) => ({
                name: row[1], // "ItemName" - assuming the second column is the name
                description: row[0], // "Group" - assuming the first column is the description
                price: parseFloat(row[3]), // "Selling Price" - assuming the fourth column is the price
                state: "Active", // Default state
                discountexempt: "False", // Default value
                costPrice: parseFloat(row[2]), // "Cost Price" - assuming the third column is the cost price
            })),
            outlet: selectedOutlet, // Use the selected outlet
            type: category, // Selected category (Food, Beverage, Others)
        };

        console.log("Uploading data:", formattedData);

        // Send the formatted data to the backend
        axios
            .post(`${process.env.REACT_APP_BASE_URL}/create-menu`, formattedData)
            .then((response) => alert("File uploaded successfully"))
            .catch((error) => console.error("Error uploading file:", error));
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className="max-w-4xl mx-auto p-6 bg-white flex-grow">
                <h2 className="text-2xl font-bold mb-4">Upload Excel File</h2>

                <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                    className="border p-2 rounded-md w-full"
                />

                {fileName && <p className="mt-2 text-gray-600">Uploaded: {fileName}</p>}

                {sheets.length > 0 && (
                    <div className="mt-4 flex gap-4 items-center">
                        <div>
                            <label className="font-bold">Select Sheet:</label>
                            <select
                                value={selectedSheet}
                                onChange={handleSheetChange}
                                className="border p-2 rounded-md ml-2"
                            >
                                {sheets.map((sheet, index) => (
                                    <option key={index} value={sheet}>
                                        {sheet}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="font-bold">Select Outlet:</label>
                            <select
                                value={selectedOutlet || ""} // Ensure the value is always set
                                onChange={(e) => setSelectedOutlet(e.target.value)}
                                className="border p-2 rounded-md ml-2"
                            >
                                <option value="" disabled>Select Outlet</option>
                                {outlets.map((outlet) => (
                                    <option key={outlet.id} value={outlet.id}>
                                        {outlet.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="font-bold">Select Category:</label>
                            <select
                                value={category || ""} // Ensure the value is always set
                                onChange={(e) => setCategory(e.target.value)}
                                className="border p-2 rounded-md ml-2"
                            >
                                <option value="" disabled>Select Category</option>
                                <option value="Food">Food</option>
                                <option value="Beverage">Beverage</option>
                                <option value="Other">Others</option>
                            </select>
                        </div>

                        <button
                            onClick={handleUpload}
                            className="bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700"
                        >
                            Upload
                        </button>
                    </div>
                )}

                {data.length > 0 && (
                    <div className="mt-6 overflow-x-auto">
                        <table className="table-auto border-collapse w-full">
                            <thead>
                                <tr className="bg-gray-200">
                                    {data[0].map((col, index) => (
                                        <th key={index} className="border px-4 py-2">
                                            {col}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.slice(1).map((row, rowIndex) => (
                                    <tr key={rowIndex} className="border">
                                        {row.map((cell, colIndex) => (
                                            <td key={colIndex} className="border px-4 py-2">
                                                {cell}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default FileUpload;
