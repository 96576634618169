import React from 'react'
import { Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const Piechart = ({ dineinTabs, FoodBeverageSum }) => {
  return (
    <div className='show-pie-chart'>
      <Pie className='pie-chart'
        data={{
          datasets: [
            {
              label: 'Number of Sales',
              data: [dineinTabs.FoodSale, dineinTabs.BeverageSale, dineinTabs.OtherSale],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
              ],
              borderWidth: 1,
            },
          ],
          labels: ['Food Sales', 'Beverage Sales', 'Other Sales'],
        }} />
      {FoodBeverageSum.length > 0 && (() => {
        let totalBeverage = 0, totalFood = 0, totalOthers = 0;

        FoodBeverageSum.forEach(item => {
          totalBeverage += Number(item.beveragetotal) || 0;
          totalFood += Number(item.foodtotal) || 0;
          totalOthers += Number(item.Othertotal) || 0;
        });

        const totalSum = totalBeverage + totalFood + totalOthers;

        return totalSum > 0 ? (
          <div className="food-beverage-qty">
            <h5>Sales Distribution (%)</h5>
            <p><span>Beverage: </span> {((totalBeverage / totalSum) * 100).toFixed(2)}%</p>
            <p><span>Food: </span> {((totalFood / totalSum) * 100).toFixed(2)}%</p>
            <p><span>Others: </span> {((totalOthers / totalSum) * 100).toFixed(2)}%</p>
          </div>
        ) : (
          <p>No sales data available.</p>
        );
      })()}


    </div>
  )
}

export default Piechart