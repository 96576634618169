import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const SalesLineChart = ({ data }) => {
    // Extract dates and sales values
    const labels = data?.dailyData.map((row) => row.Date);
    const salesData = data?.dailyData.map((row) => row.TOTALSALES);

    const chartData = {
        labels: labels, // Use dates as labels
        datasets: [
            {
                label: "Total Sales",
                data: salesData,
                borderColor: "#4CAF50", // Green line color
                backgroundColor: "rgba(76, 175, 80, 0.2)", // Light green fill
                pointBorderColor: "#4CAF50",
                pointBackgroundColor: "#fff",
                pointRadius: 5,
                pointHoverRadius: 7,
                fill: true,
                tension: 0,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: "top",
                labels: {
                    font: {
                        size: 14,
                        weight: "bold", // Bold legend for better visibility
                    },
                    color: "#333", // Dark color for contrast
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Date",
                    font: {
                        size: 14,
                        weight: "bold",
                    },
                },
                ticks: {
                    autoSkip: true, // Skip labels if too many
                    maxRotation: 45, // Tilt labels for better readability
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Total Sales",
                    font: {
                        size: 14,
                        weight: "bold",
                    },
                },
                beginAtZero: true, 
            },
        },
    };

    return (
        <div style={styles.chartContainer}>
            <Line data={chartData} options={chartOptions} />
        </div>
    );
};

const styles = {
    chartContainer: {
        width: "90%", // Fixed width but responsive
        maxWidth: "1000px", // Maximum width for better viewing
        height: "400px", // Fixed height
        margin: "20px auto", // Center the chart
        padding: "15px",
        background: "#fff",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
};

export default SalesLineChart;
