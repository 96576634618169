import React from 'react';
import { formatSalesValue } from '../../utils/formatUtils';

const SalesInDetail = ({ forecastData, exceeded, compare }) => {
  const today = new Date();
  today.setDate(today.getDate() - 1); // Set to yesterday
  const yesterday = today.toISOString().split('T')[0]; // Format the date to 'YYYY-MM-DD'

  // Sort data in ascending order based on date
  const sortedSalesData = [...forecastData].sort((a, b) => new Date(a.ds) - new Date(b.ds));

  // Filter out future dates and exclude today's date for actual sales
  const validSalesData = sortedSalesData.filter(item => item.ds <= yesterday);

  // Check if the forecast data includes yesterday's date and adjust accordingly
  const forecastDataToConsider = compare
    ? sortedSalesData.some(item => item.ds === yesterday)
      ? sortedSalesData.filter(item => item.ds <= yesterday)
      : sortedSalesData
    : sortedSalesData;

  // Actual Sales Calculation
  const startingSales = validSalesData[0]?.actual_sales || 0;
  const closingSales = validSalesData[validSalesData.length - 1]?.actual_sales || 0;
  const totalSales = validSalesData.reduce((sum, item) => sum + Number(item.actual_sales), 0);
  const highestSales = Math.max(...validSalesData.map(item => Number(item.actual_sales)));
  const lowestSales = Math.min(...validSalesData.map(item => Number(item.actual_sales)));
  const averageSales = totalSales / validSalesData.length || 0; // Average sales calculation

  // Forecast Sales Calculation (Using yhat for forecasted sales)
  const startingForecast = forecastDataToConsider[0]?.yhat || 0;
  const closingForecast = forecastDataToConsider[forecastDataToConsider.length - 1]?.yhat || 0;
  const totalForecast = forecastDataToConsider.reduce((sum, item) => sum + Number(item.yhat), 0);
  const highestForecast = Math.max(...forecastDataToConsider.map(item => Number(item.yhat)));
  const lowestForecast = Math.min(...forecastDataToConsider.map(item => Number(item.yhat)));
  const averageForecast = totalForecast / forecastDataToConsider.length || 0; // Average forecast calculation

  // Conditional styling based on comparison between actual and forecast data
  const getComparisonClass = (actualValue, forecastValue) => {
    if (actualValue > forecastValue) return 'text-green-600 font-semibold';
    if (actualValue < forecastValue) return 'text-red-600 font-semibold';
    return ''; // No color if they are equal
  };

  return (
    <div className={`flex ${exceeded ? 'flex-row p-4 gap-40' : 'flex-col'}`}>
      {/* Actual Sales Details Section */}
      <div className="flex-1">
        <h2 className="text-lg">Actual Sales Details:</h2>
        {validSalesData.length === 0 ? (
          <div>No actual sales details available.</div>
        ) : (
          <table className="min-w-full table-auto border-collapse text-sm">
            <tbody>
              <tr>
                <td className="border-b p-2 font-semibold">Starting Sales</td>
                <td className={`border-b p-2`}>{formatSalesValue(startingSales)}</td>
              </tr>
              <tr>
                <td className="border-b p-2 font-semibold">Closing Sales</td>
                <td className={`border-b p-2`}>{formatSalesValue(closingSales)}</td>
              </tr>
              <tr>
                <td className="border-b p-2 font-semibold">Total Sales</td>
                <td className={`border-b p-2 ${compare ? getComparisonClass(totalSales, totalForecast) : ''}`}>{formatSalesValue(totalSales)}</td>
              </tr>
              <tr>
                <td className="border-b p-2 font-semibold">Average Sales</td>
                <td className={`border-b p-2 ${compare ? getComparisonClass(averageSales, averageForecast) : ''}`}>{formatSalesValue(averageSales)}</td>
              </tr>
              <tr>
                <td className="border-b p-2 font-semibold">Highest Sales</td>
                <td className="border-b p-2">{formatSalesValue(highestSales)}</td>
              </tr>
              <tr>
                <td className="border-b p-2 font-semibold">Lowest Sales</td>
                <td className="border-b p-2">{formatSalesValue(lowestSales)}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      {/* Forecast Sales Details Section */}
      <div className="flex-1">
        <h2 className={`${exceeded ? '' : 'mt-4'} text-lg`}>Forecast Sales Details:</h2>
        {forecastDataToConsider.length === 0 ? (
          <div>No forecast sales details available.</div>
        ) : (
        <table className="min-w-full table-auto border-collapse text-sm">
          <tbody>
            <tr>
              <td className="border-b p-2 font-semibold">Starting Forecast</td>
              <td className="border-b p-2 truncate">{formatSalesValue(startingForecast)}</td>
            </tr>
            <tr>
              <td className="border-b p-2 font-semibold">Closing Forecast</td>
              <td className="border-b p-2">{formatSalesValue(closingForecast)}</td>
            </tr>
            <tr>
              <td className="border-b p-2 font-semibold">Total Forecast</td>
              <td className="border-b p-2">{formatSalesValue(totalForecast)}</td>
            </tr>
            <tr>
              <td className="border-b p-2 font-semibold truncate">Average Forecast</td>
              <td className="border-b p-2 truncate">{formatSalesValue(averageForecast)}</td>
            </tr>
            <tr>
              <td className="border-b p-2 font-semibold">Highest Forecast</td>
              <td className="border-b p-2">{formatSalesValue(highestForecast)}</td>
            </tr>
            <tr>
              <td className="border-b p-2 font-semibold">Lowest Forecast</td>
              <td className="border-b p-2">{formatSalesValue(lowestForecast)}</td>
            </tr>
          </tbody>
        </table>
        )}
      </div>
    </div>
  );
};

export default SalesInDetail;
