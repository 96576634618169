import React from 'react';

const TopVendor = ({ TopVendorinfo }) => {
  return (
    <div className="table-responsive-food -z-0">
      <table className="table-food">
        <thead className="table-background ">
          <tr className="sticky-header bg-white">
            <th>Vendor</th>
            <th>Net Amount</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        
        {TopVendorinfo.length > 0 ? (
          TopVendorinfo.map((item, index) => (
            <tr key={index}>
              <td>{item.Company_Name}</td>
              <td>{item.NetAmount}</td>
              <td>{item.TotalAmount}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="3">No data available</td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default TopVendor;
